.order-item-container .item-content.mobile {
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  height: auto;
}
.order-item-container #capture {
  z-index: -2;
  position: absolute;
  left: -99999px;
}
.order-item-container .detail-title {
  font-size: 14px;
  font-weight: 600;
}

.order-item-container .detail-title-pvpr {
  font-size: 14px;
  font-weight: 300;
}

.order-item-container .detail-title-normal {
  font-size: 15px;
  font-weight: 300;
}

.order-item-container .box-btn button {
  margin: 23px 0;
}

.order-item-container .box-btn .see-more-btn:hover {
  background-color: #53bfd4 !important;
}

.order-item-container .box-btn .repeat-btn:hover {
  background-color: #27282e !important;
}

.order-item-container .box-btn .pdf-btn:hover {
  background-color: #53bfd4 !important;
}

.order-item-container .box-products {
  max-height: 223px;
}
.order-item-container .box-products .list-products {
  width: 100%;
  max-height: 205px;
  overflow: auto;
}
.order-item-container .box-products .list-products::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: #f0f0f7;
}

.order-item-container .box-products .list-products::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.order-item-container .item-content.desktop {
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  height: 275px;
}

.order-item-container .item-content .detail-btn.desktop {
  height: 30px;
  width: 100px;
  border: 0;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
}

.order-item-container .item-content .detail-btn.mobile {
  height: 24px;
  width: 24px;
}

.order-item-container .item-content .detail-btn.mobile img {
  height: 18px;
  width: 18px;
  object-fit: contain;
}
.order-item-container .box-btn.mobile div {
  justify-content: space-between;
  width: 100%;
}
/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .order-item-container .box-btn.mobile div {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .order-item-container .box-btn button {
    margin: 10px 0;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1100px) {
  .order-item-container .box-btn.mobile div {
    justify-content: space-around;
  }
}
