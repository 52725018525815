.filters-box.desktop {
  padding: 0 80px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
}
.filters-box.mobile {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  padding: 0 15px;
}
.filters-box .categories-box button {
  min-width: 150px;
  color: #525358;
  background-color: #f5f4fb;
  background-repeat: no-repeat;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
}

.filters-box.desktop .categories-box button:hover {
  /* color: #53bfd4; */
  border-bottom: solid 2px #53bfd4;
}

.filters-box .categories-box .is-active {
  border-bottom: solid 2px #53bfd4;
  color: #525358;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .filters-box.mobile {
    padding: 0 45px;
  }
}

@media only screen and (min-device-width: 1440px) {
  .filters-box.desktop {
    padding: 0 115px;
  }
}
/* @media only screen and (min-device-width: 1700px) {
  .filters-box.desktop {
    padding: 0px 173px;
  }
 
}
@media only screen and (min-device-width: 2200px) {
  .filters-box.desktop {
    padding: 0px 295px;
  }
 
} */

.promotions-dropdown-container {
  padding-left: 30px;
  padding-right: 30px;
}

.promotions-dropdown-container .promotions-dropdown {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.promotions-dropdown-container .promotions-dropdown .dropdown-button {
  height: 47px;
  background-color: #f5f4fb;
  border: 0;
  border-radius: 0 !important;
  padding-left: 30px !important;
  color: black;
}

.promotions-dropdown-container .promotions-dropdown .dropdown-button {
  color: #747474;
}

.promotions-dropdown-container .promotions-dropdown li {
  color: #525358;
}
.promotions-dropdown-container .promotions-dropdown .placeholder {
  color: #525358 !important;
}

.promotions-dropdown-container .promotions-dropdown .dropdown-icon {
  height: 30px;
  width: 30px;
}

.promotions-dropdown-container .promotions-dropdown .dropdown-options {
  z-index: 1;
  width: 100%;
  background-color: #f5f4fb;
  padding-left: 30px !important;
}

.promotions-dropdown-container .promotions-dropdown .dropdown-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}