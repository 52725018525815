.success-container {
  width: 100%;
}

.success-container.mobile {
  min-height: 447px;
}

.success-container .success-content {
  position: relative;
}

.success-container.desktop .success-content .ok-btn:hover {
  background-color: #53bfd4 !important;
}

.success-container .success-content .success-image.desktop {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.success-container .success-content .success-image.mobile {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.success-container .success-content .success-text.mobile .title {
  font-size: 22px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.success-container .success-content .success-text.mobile .text {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.success-container .success-content .success-text.desktop .title {
  font-size: 24px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.success-container .success-content .success-text.desktop .text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.success-container .success-content button {
  border-radius: 6px;
  height: 35px;
  font-size: 14px;
  width: 120px;
  padding: 0;
}
