.detail-container {
  padding-bottom: 80px;
  overflow: auto;
}

.detail-container.desktop {
  padding-top: 80px;
}

.detail-container.mobile {
  padding-top: 100px;
}

.detail-container .separator {
  height: 1px;
}

.detail-container .separator-vertical {
  width: 1px;
}

.detail-container .detail-images-container.desktop {
  border-right: 1px solid #e3e2e4;
}

.detail-container .detail-images-container .product-images.desktop {
  padding-top: 100px;
  padding-bottom: 100px;
}

.detail-container .detail-images-container .product-images.mobile {
  padding-top: 60px;
  padding-bottom: 60px;
}


.detail-container .detail-images-container .product-images .big-image-content.desktop {
  position: relative;
  height: auto;
}

.detail-container .detail-images-container .product-images .big-image-content.mobile {
  position: relative;
  width: 250px;
  height: auto;
}

.detail-container .detail-images-container .product-images .big-image-content.desktop .big-image {
  object-fit: contain;
  width: 100%;
  height: 800px;
}

.detail-container .detail-images-container .product-images .big-image-content.mobile .big-image {
  object-fit: contain;
  width: 100%;
  height: 400px;
}

.detail-container .detail-images-container .product-images .zoom-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  /* border-radius: 3px; */
  padding: 0;
}

.detail-container .detail-images-container .product-images .zoom-btn img {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

.detail-container .detail-images-container .product-images .product-image-thumbnails.mobile,
.detail-container .detail-images-container .product-images .product-image-thumbnails.mobile .slick-track {
  width: 250px;
}

.detail-container .detail-images-container .product-images .product-image-thumbnails.desktop,
.detail-container .detail-images-container .product-images .product-image-thumbnails.desktop .slick-track {
  width: 300px;
}

.detail-container .detail-images-container .product-images .product-thumb {
  height: 80px;
}

.detail-container .detail-images-container .product-images .product-thumb .product-thumb-content {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.detail-container .detail-images-container .product-images .product-thumb .product-thumb-content img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.detail-container .full-screen-container {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  padding: 50px;
}

.detail-container .full-screen-container .closebtn {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.detail-container .full-screen-container .full-screen-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}


.detail-container .detail-item-container.desktop {
  padding: 24px 42px 24px 42px !important;
  position: relative;
}

.detail-container .detail-item-container.mobile {
  padding: 15px !important;
  padding-top: 40px !important;
  padding-bottom: 24px !important;
}

.detail-container .detail-item-container.desktop .menu-mobile { 
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.detail-container .detail-item-container.mobile .menu-mobile { 
  padding-left: 0;
  padding-right: 0;
}

.detail-container .detail-item-container.desktop .menu-mobile ul,
.detail-container .detail-item-container.mobile .menu-mobile ul {
  padding: 0;
  display: inline-table;
  width: 100%;
  margin-bottom: 0;
}

.detail-container .detail-item-container.desktop .menu-mobile ul {
  height: 50px !important;
}

.detail-container .detail-item-container.mobile .menu-mobile ul {
  height: 35px !important;
}

.detail-container .detail-item-container .menu-mobile li {
  display: table-cell;
  vertical-align: middle;
  width: 49.5%;
  text-align: center;
  text-decoration: none;
  background-color: #f5f4fb;
  border: 1px solid white;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  cursor: pointer;
  color: #a9a9ab;
}

.detail-container .detail-item-container .menu-mobile.three li {
  width: 33%;
}

.detail-container .detail-item-container.desktop .menu-mobile li {
  font-size: 14px;
  height: 50px;
}

.detail-container .detail-item-container.mobile .menu-mobile li {
  font-size: 12px;
  height: 35px;
}

.detail-container .detail-item-container.desktop .menu-mobile li:hover {
  border-bottom: 3px solid #53bfd4;
  color: #525358;
}

.detail-container .detail-item-container.desktop .menu-mobile .selected,
.detail-container .detail-item-container.mobile .menu-mobile .selected {
  border-bottom: 3px solid #53bfd4;
  color: #525358;
}

.detail-container .detail-item-container .menu-mobile .info-text-container {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #525358;
}

.detail-container .detail-item-container.mobile .menu-mobile .info-text-container {
  font-size: 12px;
}

.detail-container .detail-item-container .cart-btn {
  height: 42px;
  width: 150px;
  background-color: #252530;
  border: 0;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.detail-container .detail-item-container .cart-btn:hover {
  background-color: #53bfd4;
}

.detail-container .detail-item-container .cart-btn:disabled {
  background-color: #252530;
  opacity: 0.3;
}

.detail-container .detail-item-container .cart-btn img {
  width: 20px;
  height: 20px;
}

.detail-container .detail-item-container.desktop .two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 60px;
}

.detail-container .detail-item-container .head-product-name.mobile {
  font-size: 18px;
  font-weight: bolder;
  line-height: 1.2;
}

.detail-container .detail-item-container .head-product-name.desktop {
  font-size: 24px;
  font-weight: bolder;
  line-height: 1.2;
}
.detail-container .detail-item-container .head-product-name {
  font-family: HelveticaNeuBold;
}

.detail-container .detail-item-container .head-product-cat.mobile {
  font-size: 16px;
  font-weight: 500;
}

.detail-container .detail-item-container .head-product-cat.desktop {
  font-size: 24px;
  font-weight: 500;
}

.detail-container .detail-item-container .head-product-price.mobile {
  font-size: 16px;
  font-weight: 600;
}

.detail-container .detail-item-container .head-product-price.desktop {
  font-size: 24px;
  font-weight: 600;
}

.detail-container .detail-item-container .product-title.mobile {
  font-size: 14px;
  font-weight: bolder;
}

.detail-container .detail-item-container .product-title.desktop {
  font-size: 16px;
  font-weight: bolder;
}

.detail-container .detail-item-container .product-info.mobile {
  font-size: 14px;
  font-weight: 400;
}

.detail-container .detail-item-container .product-info.desktop {
  font-size: 16px;
  font-weight: 400;
}

.detail-container .detail-item-container .product-description.desktop {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
}

.detail-container .detail-item-container .product-description.mobile {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
}

.detail-container .detail-item-container.desktop .pvp-info-text {
  position: absolute;
  bottom: 10px;
  font-size: 11px;
  font-weight: 300;
  color: #a9a9ab;
}

.detail-container .detail-item-container.mobile .pvp-info-text {
  font-size: 10px;
  font-weight: 300;
  color: #a9a9ab;
  line-height: 1.31 !important;
  margin-top: 24px;
}

.detail-container .recommended-slider.mobile {
  padding-top: 30px;
  padding-bottom: 30px;
}

.detail-container .recommended-slider.desktop {
  padding: 24px 42px 24px 42px;
}
.detail-container .product-price {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #53bfd4;
}
.detail-container .product-price .price-before:first-child {
  position: relative;
  height: 25px;
}
.detail-container .product-price.mobile .price-before:first-child {
  position: relative;
  height: 25px;
  padding-top: 2px;
}

.detail-container .product-price.desktop .price-before:first-child::after {
  content: '';
  border-bottom: 3px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
}
.detail-container .product-price.mobile .price-before:first-child::after {
  content: '';
  border-bottom: 2px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 45%;
  width: 100%;
}

.detail-container .product-price.mobile {
  font-size: 16px;
}

.detail-container .product-price.desktop {
  font-size: 20px;
}
.detail-container .expire-price-mobile {
  font-size: 14px;
  color: #2b2d36;
}
.detail-container .product-price.desktop .expire-price {
  font-size: 12px;
  color: #2b2d36;
}

.detail-container .image-container.mobile {
  max-height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.detail-container .image-container.mobile img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail-container .image-container.desktop {
  max-height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.detail-container .image-container.desktop img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .detail-container .detail-item-container.mobile {

  }
  
}