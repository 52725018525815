.product-size-content {
    height: 80px;
}

.product-size-content-mobile {
    height: 80px;
}

.product-size-content:hover {
    border: 1px solid #53bfd4 !important;
    margin-top: -1px !important;
    cursor: pointer;
}

.product-size-content.selected,
.product-size-content-mobile.selected {
    border: 1px solid #53bfd4 !important;
    margin-top: -1px !important;
}

.product-size-content:first-child,
.product-size-content-mobile:first-child {
    border-top: 1px solid #e3e2e4;
    border-bottom: 1px solid #e3e2e4;
}

.product-size-content:not(:first-child),
.product-size-content-mobile:not(:first-child) {
    border-bottom: 1px solid #e3e2e4;
}

.product-size-content .img-size img {
    width: 100%;
    max-width: 70px;
    height: 70px;
    overflow: hidden;
    object-fit: contain;
}

.product-size-content-mobile .img-size-mobile img {
    width: 100%;
    max-width: 60px;
    height: 60px;
    overflow: hidden;
    object-fit: contain;
}

.product-size-content .size-name,
.product-size-content .size-price,
.product-size-content .size-price-pvpr,
.product-size-content .size-price-before {
    color: black;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
}

.product-size-content-mobile .size-name-mobile,
.product-size-content-mobile .size-price-mobile,
.product-size-content-mobile .size-price-mobile-no-offer,
.product-size-content-mobile .size-price-pvpr-mobile,
.product-size-content-mobile .size-price-before-mobile {
    color: black;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
}

.product-size-content-mobile .size-price-pvpr-mobile {
    color: #a9a9ab !important;
    font-weight: 300;
}

.product-size-content .size-price {
    width: 70px;
    text-align: center;
}

.product-size-content-mobile .size-price-mobile-no-offer {
    margin-right: 4px;
}

.product-size-content .size-price-before {
    text-decoration: line-through;
    color: #53bfd4;
}

.product-size-content-mobile .size-price-before-mobile {
    text-decoration: line-through;
    color: #53bfd4;
    margin-right: 8px;
}

.product-size-content .size-price-pvpr {
    width: 125px;
    color: #a9a9ab;
    font-weight: 300;
    text-align: center;
}

.product-size-content .cart-btn {
    height: 30px !important;
    width: 100px !important;
    background-color: #53bfd4 !important;
    border: 0;
    font-size: 14px !important;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: white;
}

.product-size-content-mobile .cart-btn-mobile {
    height: 30px !important;
    width: 80px !important;
    background-color: #53bfd4 !important;
    border: 0;
    font-size: 12px !important;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: white;
    margin-left: 15px;
    margin-right: 15px;
}

.product-size-content .cart-btn:hover {
    background-color: #252530 !important;
}

.product-size-content .cart-btn:disabled {
    opacity: 0.3;
}