.admin-register-container.mobile {
  min-height: 100%;
  padding: 20px 30px;
}
.admin-register-container.desktop {
  padding: 62px 90px;
}

.admin-register-container .admin-register-content {
  position: relative;
}

.admin-register-container .admin-register-content .admin-register-text.mobile .title {
  font-size: 24px;
  font-family: HelveticaNeuBold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #26282e;
  text-align: center;
}

.admin-register-container .admin-register-content .admin-register-text.desktop .title {
  font-size: 35px;
  font-family: HelveticaNeuBold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #26282e;
  text-align: center;
}

.admin-register-container .admin-register-content .admin-register-form.mobile,
.admin-register-container .admin-register-content .admin-register-form.mobile input {
  color: black;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.admin-register-container .admin-register-content .admin-register-form.desktop,
.admin-register-container .admin-register-content .admin-register-form.desktop input {
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.admin-register-container .admin-register-content .admin-register-form input {
  margin-top: 15px;
  height: 50px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
  padding-left: 30px !important;
  color: black;
}

.admin-register-container .admin-register-content .admin-register-form input:hover,
.admin-register-container .admin-register-content .admin-register-form input:focus {
  border: 1px solid black;
}

.admin-register-container .admin-register-content .admin-register-form input.is-invalid {
  border: 1px solid red;
  background-image: url(/misc/icons/input_error.svg);
}

.admin-register-container .admin-register-content .admin-register-button.mobile {
  padding: 0;
  margin: 36px auto;
  font-size: 16px;
}

.admin-register-container .admin-register-content .admin-register-button.desktop {
  padding: 0;
  margin: 36px auto;
  font-size: 16px;
}

.admin-register-container .admin-register-content .admin-register-button:disabled {
  opacity: 0.3;
}
@media only screen and (min-width: 700px) and (max-width: 1095px) {
  .admin-register-container.mobile {
    padding: 40px 60px;
  }
}
@media only screen and (min-device-width: 1440px) {
  .admin-register-container.desktop {
    padding: 65px 160px;
  }
}
