html,
body,
#root,
#root > div {
  height: 100%;
}

.not-found-container {
  background-color: #2c2c37;
}

.not-found-container .not-found-content.desktop .logo {
  width: 150px;
  margin-left: 40px;
}
.not-found-container .not-found-content.mobile .logo {
  width: 90px;
  margin-left: 20px;
}
.not-found-container .not-found-content .title.mobile {
  font-size: 100px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
}

.not-found-container .not-found-content .title.desktop {
  font-size: 200px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
}

.not-found-container .not-found-content .subtitle.mobile {
  font-size: 22px;
}

.not-found-container .not-found-content .subtitle.desktop {
  font-size: 40px;
}

.not-found-container .not-found-content .description {
  color: #2b2d36;
}

.not-found-container .not-found-content .description.desktop {
  font-size: 20px;
}
.not-found-container .not-found-content .description.mobile {
  font-size: 14px;
}

.not-found-container .not-found-content .btn-home {
  width: 249.8px;
  height: 47.7px;
  background-color: black;
  color: white;
}
.not-found-container .not-found-content a {
  text-decoration: none !important;
}
