.admin-addresses-container.mobile {
    min-height: 100%;
    padding: 20px 30px;
}

.admin-addresses-container.desktop {
    padding: 62px 90px;
}
  
.admin-addresses-container .admin-addresses-content {
    position: relative;
}

.admin-addresses-container .admin-addresses-content .admin-addresses-text.mobile .title {
    font-size: 24px;
    font-family: HelveticaNeuBold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: #26282e;
    text-align: center;
}
  
.admin-addresses-container .admin-addresses-content .admin-addresses-text.desktop .title {
    font-size: 36px;
    font-family: HelveticaNeuBold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: #26282e;
    text-align: center;
}

.admin-addresses-container .admin-addresses-content .admin-addresses-form.mobile,
.admin-addresses-container .admin-addresses-content .admin-addresses-form.mobile input,
.admin-addresses-container .admin-addresses-content .admin-addresses-form.mobile select {
    color: black;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
}

.admin-addresses-container .admin-addresses-content .admin-addresses-form.desktop,
.admin-addresses-container .admin-addresses-content .admin-addresses-form.desktop input,
.admin-addresses-container .admin-addresses-content .admin-addresses-form.desktop select {
    color: black;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
}

.admin-addresses-container .admin-addresses-content .admin-addresses-form input,
.admin-addresses-container .admin-addresses-content .admin-addresses-form select {
    margin-top: 15px;
    height: 40px;
    background-color: white;
    border: 0;
    border-radius: 0 !important;
    padding-left: 30px !important;
    color: black;
}

#province,
#country{
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.admin-addresses-container .admin-addresses-content .admin-addresses-form input:hover,
.admin-addresses-container .admin-addresses-content .admin-addresses-form input:focus,
.admin-addresses-container .admin-addresses-content .admin-addresses-form select:hover,
.admin-addresses-container .admin-addresses-content .admin-addresses-form select:focus {
    border: 1px solid black;
}

.admin-addresses-container .admin-addresses-content .admin-addresses-form input.is-invalid,
.admin-addresses-container .admin-addresses-content .admin-addresses-form select.is-invalid {
    border: 1px solid red;
    background-image: url(/misc/icons/input_error.svg);
}

.admin-addresses-container .admin-addresses-content .admin-addresses-button.mobile {
    padding: 0;
    margin: 36px auto;
}
  
.admin-addresses-container .admin-addresses-content .admin-addresses-button.desktop {
    padding: 0;
    margin: 36px auto;
}
  
.admin-addresses-container .admin-addresses-content .admin-addresses-button:disabled,
.admin-addresses-container .save-all-button:disabled {
    opacity: 0.3;
}

.admin-addresses-container .save-all-button {
    padding: 0;
    margin: 36px auto;
}

.admin-addresses-container .skip-button {
    color: #26282e;
    text-decoration: underline;
}

.admin-addresses-container .back-btn.desktop {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 42px 35px;
}
  
.admin-addresses-container .back-btn.mobile {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 15px;
}
  
.admin-addresses-container .icon-back.mobile {
    height: 18px;
    width: 18px;
}
  
.admin-addresses-container .icon-back.desktop {
    height: 20px;
    width: 20px;
}
  
.admin-addresses-container .back-text {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
}
  
.admin-addresses-container .back-text.mobile {
    font-weight: 300;
    font-size: 14px;
}
  
.admin-addresses-container .back-text.desktop {
    font-weight: 400;
    font-size: 16px;
}

.admin-addresses-container .admin-addresses-content .desktop .icon-add{
    width: 22px;
    height: 22px;
    margin-right: 5px;
}

.admin-addresses-container .admin-addresses-content .mobile .icon-add{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.admin-addresses-container .item-addresses .empty-addresses img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
}

.admin-addresses-container .item-addresses .empty-addresses span {
    color: #26282e;
    font-size: 14px;
    font-family: HelveticaNeuBold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    display: block;
    text-align: center;
}

@media only screen and (min-width: 700px) and (max-width: 1095px) {
    .admin-addresses-container.mobile {
      padding: 40px 60px;
    }
}

@media only screen and (min-device-width: 1440px) {
    .admin-addresses-container.desktop {
      padding: 65px 160px;
    }

    .admin-addresses-container .back-btn.desktop {
        top: 0px;
    }
}