.promotions-list-box {
}
.promotions-list-box .container-products-top {
  height: 120px;
}
.promotions-list-box .container-products-bottom {
  position: relative;
  height: 'auto';
}
.promotions-list-box .container-products-bottom .products-promotion.desktop {
  display: flex;
  flex-wrap: wrap;
  top: -125px;
  padding: 30px 5%;
  width: 100%;
}
.promotions-list-box .container-products-bottom .products-promotion.mobile {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px;
  width: 100%;
}

.promotions-list-box .promotions-product {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.promotions-list-box .separator {
  height: 1px;
  width: 100%;
}

.promotions-list-box .promotions-product.mobile {
  height: 200px;
}

.promotions-list-box .promotions-product.desktop {
  height: 250px;
  width: 100%;
}

.promotions-list-box .promotions-product img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.promotions-list-box .promotions-product .hover-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.promotions-list-box .promotions-product:hover .hover-img {
  display: inline;
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.promotions-list-box a {
  text-decoration: none !important;
}

.promotions-list-box .product-name {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}

.promotions-list-box .product-name.desktop .name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-height: 35px;
}

.promotions-list-box .product-name.mobile .name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-height: 35px;
}

.promotions-list-box .product-name.desktop span:first-child {
  font-size: 14px;
  font-weight: bold;
}

.promotions-list-box .product-name.mobile span:first-child {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  min-height: 42px;
}
.promotions-list-box .product-name .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 3px 0 !important;
}
.promotions-list-box .product-name.desktop .description {
  font-size: 13px;
  -webkit-line-clamp: 1;
  min-height: 17px;
}
.promotions-list-box .product-name.mobile .description {
  font-size: 11px;
  -webkit-line-clamp: 2;
  min-height: 26px;
}

.promotions-list-box .product-price {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #53bfd4;
}

.promotions-list-box .product-price.desktop .price.pvpr {
  color: #a9a9ab;
  font-weight: 300;
  font-size: 14px;
}

.promotions-list-box .product-price.mobile .price.pvpr {
  color: #a9a9ab;
  font-weight: 300;
  font-size: 12px;
}

.promotions-list-box .product-price.desktop {
  left: 10px;
  justify-content: flex-start;
}
.promotions-list-box .product-price.mobile {
  font-size: 14px;
  justify-content: flex-start;
}
.promotions-list-box .product-price .price-before:first-child {
  position: relative;
  height: 25px;
}

.promotions-list-box .product-price.desktop .price-before:first-child::after {
  content: '';
  border-bottom: 2px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 37%;
  width: 100%;
}
.promotions-list-box .product-price.mobile .price-before:first-child::after {
  content: '';
  border-bottom: 2px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 35%;
  width: 100%;
}

.promotions-list-box .product-price.desktop {
  font-size: 16px;
}

.promotions-list-box .product-promotion {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #2c2929;
}
/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .promotions-list-box .product-price.mobile {
    font-size: 12px;
  }
  .promotions-list-box .product-price.mobile .price-before:first-child::after {
    top: 28%;
  }
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .promotions-list-box .product-name.mobile {
    font-size: 14px;
  }
  .promotions-list-box .container-products-bottom .products-promotion.mobile {
    padding: 30px 25px;
  }
  .promotions-list-box .product-price.mobile {
    font-size: 14px;
    justify-content: flex-start;
    left: 10px;
  }
  .promotions-list-box .product-price.mobile .price-before:first-child::after {
    top: 32%;
  }
  .promotions-list-box .product-name.mobile span:first-child {
    font-size: 12px;
    -webkit-line-clamp: 1;
    min-height: 15px;
  }
}

@media only screen and (min-device-width: 1440px) {
  .promotions-list-box .container-products-bottom .products-promotion.desktop {
    padding: 30px 100px 80px;
  }
  .promotions-list-box .product-price.desktop .price-before:first-child::after {
    top: 30%;
  }
}
