.count-container.desktop {
  height: 30px;
  width: 80px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}
.count-container {
  height: 30px;
  width: 80px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}
.count-container-detail {
  height: 42px;
  width: 90px;
  margin-right: 15px;
  font-size: 14px;
}

.count-container img,
.count-container-detail img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  cursor: pointer;
}
@media only screen and (min-width: 801px) and (max-width: 1100px) {
  .count-container {
    width: 40px;
    font-size: 12px;
    margin-right: 5px;
  }
 
  .count-container img{
    width: 14px;
    height: 14px;
    object-fit: contain;
    cursor: pointer;
  }
}
