.nav-bar-container {
  z-index: 5;
  position: fixed !important;
  width: 100%;
  top: 0;
}
.nav-bar-container-home {
  z-index: 5;
  width: 100%;
}

.padding-home.desktop {
  position: absolute !important;
  top: 40px;
}
.padding-home.mobile {
  position: absolute !important;
  top: 50px;
}
.padding-web {
  position: fixed !important;
  top: 0;
}

.nav-bar-container.mobile,
.nav-bar-container-home.mobile {
  height: 100px;
}

.nav-bar-container .container-mobile,
.nav-bar-container-home .container-mobile {
  height: 50px !important;
}

.nav-bar-container.desktop,
.nav-bar-container-home.desktop {
  height: 80px;
}

.nav-bar-container .logo-mobile-container,
.nav-bar-container-home .logo-mobile-container {
  height: 50px;
  background-color: blue;
}

.nav-bar-container .logo-mobile-container .main-logo,
.nav-bar-container-home .logo-mobile-container .main-logo {
  width: 220px;
}

.nav-bar-container .nav-menu,
.nav-bar-container-home .nav-menu {
  position: absolute;
  height: 100%;
  left: 50%;
  right: 50%;
  z-index: 1;
}

.nav-bar-container .nav-menu a,
.nav-bar-container-home .nav-menu a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.nav-bar-container .nav-menu .promos-btn,
.nav-bar-container-home .nav-menu .promos-btn {
  background-color: Transparent;
  border: none;
  padding: 0;
}

.nav-bar-container .nav-menu span:hover,
.nav-bar-container .nav-menu .promos-btn:hover,
.nav-bar-container-home .nav-menu span:hover,
.nav-bar-container-home .nav-menu .promos-btn:hover {
  color: #53bfd4 !important;
}

.nav-bar-container .main-logo.desktop,
.nav-bar-container-home .main-logo.desktop {
  width: 280px;
  height: auto;
}

.nav-bar-container .desktop .icons-box,
.nav-bar-container-home .desktop .icons-box {
  width: 50px;
  height: 55px;
  margin-right: 24px;
  margin-top: 14px;
  text-decoration: none;
}

.nav-bar-container .desktop .icons-box span,
.nav-bar-container .desktop .cart-icon-container span,
.nav-bar-container-home .desktop .icons-box span,
.nav-bar-container-home .desktop .cart-icon-container span {
  text-align: center;
  font-size: 12px;
  margin-top: 2px;
}
.nav-bar-container .desktop .nav-icon,
.nav-bar-container-home .desktop .nav-icon {
  width: 24px;
  height: 24px;
}

.nav-bar-container .mobile .nav-icon,
.nav-bar-container-home .mobile .nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.nav-bar-container .menu-btn.mobile .menu-icon,
.nav-bar-container-home .menu-btn.mobile .menu-icon {
  width: 20px;
  height: 20px;
}

.nav-bar-container .menu-btn,
.nav-bar-container-home .menu-btn {
  z-index: 1;
}

.nav-bar-container .cart-icon-container.desktop,
.nav-bar-container-home .cart-icon-container.desktop {
  position: relative;
  margin-right: 24px;
  margin-left: -5px;
  margin-top: 14px;
  width: 50px;
  height: 55px;
}
.nav-bar-container .cart-icon-container.mobile,
.nav-bar-container-home .cart-icon-container.mobile {
  position: relative;
  margin-left: -5px;
  margin-top: 30px;
  width: 50px;
  height: 55px;
}

.nav-bar-container .cart-badge,
.nav-bar-container-home .cart-badge {
  position: absolute;
  background-color: #53bfd4;
  border-radius: 10px;
  font-size: 10px;
  width: 15px;
  height: 15px;
}

.nav-bar-container .cart-badge.mobile,
.nav-bar-container-home .cart-badge.mobile {
  right: 20px;
  top: -5px;
}

.nav-bar-container .cart-badge.desktop,
.nav-bar-container-home .cart-badge.desktop {
  right: 0px;
  top: -10px;
}

.nav-bar-container .sidenav,
.nav-bar-container-home .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.nav-bar-container .sidenav a,
.nav-bar-container .sidenav button,
.nav-bar-container-home .sidenav a,
.nav-bar-container-home .sidenav button {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 14px;
  display: block;
  transition: 0.3s;
}

.nav-bar-container .sidenav a:hover,
.nav-bar-container-home .sidenav a:hover {
  text-decoration: none;
}

.nav-bar-container .sidenav .closebtn,
.nav-bar-container-home .sidenav .closebtn {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 25px;
  height: 25px;
}

.nav-bar-container .sidenav .nav-icon-mobile,
.nav-bar-container-home .sidenav .nav-icon-mobile {
  width: 18px;
  height: 18px;
}

.nav-bar-container .sidenav .nav-main-logo,
.nav-bar-container-home .sidenav .nav-main-logo {
  width: 180px;
  position: absolute;
  top: 28px;
  left: 12px;
}

.nav-bar-container .nav-menu a,
.nav-bar-container .nav-menu button,
.nav-bar-container-home .nav-menu a,
.nav-bar-container-home .nav-menu button {
  margin: 0 1rem !important;
}
.nav-bar-container .box-logo,
.nav-bar-container-home .box-logo {
  padding: 0 0 0 3rem !important;
}

@media only screen and (max-width: 1240px) {
  .nav-bar-container .nav-menu a,
  .nav-bar-container .nav-menu button,
  .nav-bar-container-home .nav-menu a,
  .nav-bar-container-home .nav-menu button {
    width: 80px;
  }
  .nav-bar-container .main-logo.desktop,
  .nav-bar-container-home .main-logo.desktop {
    width: 200px;
    height: auto;
  }
  .nav-bar-container .box-logo,
  .nav-bar-container-home .box-logo {
    padding: 0 0 0 3rem !important;
  }

  .nav-bar-container .nav-menu,
  .nav-bar-container-home .nav-menu {
    left: 45%;
  }
  .nav-bar-container .desktop .icons-box,
  .nav-bar-container-home .desktop .icons-box {
    width: 42px;
    height: 50px;
    margin-right: 14px;
    margin-top: 8px;
  }

  .nav-bar-container .cart-icon-container.desktop,
  .nav-bar-container-home .cart-icon-container.desktop {
    position: relative;
    margin-right: 14px;
    margin-left: -5px;
    margin-top: 13px;
    width: 40px;
    height: 55px;
  }
  .nav-bar-container .desktop .icons-box span,
  .nav-bar-container .desktop .cart-icon-container span,
  .nav-bar-container-home .desktop .icons-box span,
  .nav-bar-container-home .desktop .cart-icon-container span {
    text-align: center;
    font-size: 10px;
    margin-top: 2px;
  }
  .nav-bar-container .nav-menu a,
  .nav-bar-container .nav-menu button,
  .nav-bar-container-home .nav-menu a,
  .nav-bar-container-home .nav-menu button {
    text-decoration: none;
    font-size: 14px !important;
  }

  .nav-bar-container .desktop .nav-icon,
  .nav-bar-container-home .desktop .nav-icon {
    width: 25px !important;
    height: 25px !important;
  }
}
@media only screen and (min-device-width: 1440px) {
  .nav-bar-container.desktop,
  .nav-bar-container-home.desktop {
    height: 80px;
  }
  .nav-bar-container .nav-menu a,
  .nav-bar-container .nav-menu button,
  .nav-bar-container-home .nav-menu a,
  .nav-bar-container-home .nav-menu button {
    text-decoration: none;
    font-size: 16px;
  }
  .nav-bar-container .desktop .nav-icon,
  .nav-bar-container-home .desktop .nav-icon {
    width: 30px;
    height: 30px;
  }
  .nav-bar-container .box-logo,
  .nav-bar-container-home .box-logo {
    padding: 0 0 0 3rem !important;
  }
}
@media only screen and (min-device-width: 1700px) {
  .nav-bar-container .desktop .icons-box,
  .nav-bar-container-home .desktop .icons-box {
    margin-right: 24px;
  }
  .nav-bar-container.desktop,
  .nav-bar-container-home.desktop {
    height: 80px;
  }
  .nav-bar-container .nav-menu a,
  .nav-bar-container .nav-menu button,
  .nav-bar-container-home .nav-menu a,
  .nav-bar-container-home .nav-menu button {
    text-decoration: none;
    font-size: 18px;
  }
  .nav-bar-container .desktop .nav-icon,
  .nav-bar-container-home .desktop .nav-icon {
    width: 24px;
    height: 24px;
  }
}
@media only screen and (min-device-width: 2200px) {
  .nav-bar-container .desktop .icons-box,
  .nav-bar-container-home .desktop .icons-box {
    margin-right: 24px;
  }
  .nav-bar-container.desktop,
  .nav-bar-container-home.desktop {
    height: 80px;
  }
  .nav-bar-container .nav-menu a,
  .nav-bar-container .nav-menu button,
  .nav-bar-container-home .nav-menu a,
  .nav-bar-container-home .nav-menu button {
    text-decoration: none;
    font-size: 18px;
  }
  .nav-bar-container .desktop .nav-icon,
  .nav-bar-container-home .desktop .nav-icon {
    width: 24px;
    height: 24px;
  }
}
