.address-info-content {
  width: 360px;
  padding: 0 1rem;
}

.address-container {
  /* border-radius: 6px; */
}

.address-container.mobile {
  font-size: 14px;
}

.address-container.desktop {
  font-size: 16px;
}

.address-container .address-title.mobile {
  height: 40px;
  padding-left: 24px;
  font-family: HelveticaNeuBold;
}

.address-container .address-title.desktop {
  height: 50px;
  padding-left: 40px;
  font-family: HelveticaNeuBold;
}

.address-container .address-title.mobile img {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.address-container .address-title.desktop img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.address-container .address-title .edit {
  cursor: pointer;
}

.address-container .address-info.mobile {
  padding: 24px;
  color: slategray;
  border: 1px solid #e3e2e4;
}

.address-container .address-info.desktop {
  padding: 40px;
  color: slategray;
  border: 1px solid #e3e2e4;
  height: 200px;
}
