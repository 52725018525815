.terms-container {
  background-color: #f5f4fb;
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}

.terms-container.mobile {
  padding-top: 100px;
}

.terms-container.desktop {
  padding-top: 80px;
}

.terms-container .head-image {
  width: 100%;
  object-fit: cover;
}

.terms-container .terms-content.mobile {
  padding: 15px;
}

.terms-container .terms-content.desktop {
  padding: 100px;
  width: 60%;
  margin: 0 auto;
}

.terms-container .terms-content .terms-text.mobile .title {
  font-size: 22px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
  font-family: HelveticaNeuBold;
}

.terms-container .terms-content .terms-text.mobile .text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #898383;
  text-align: center;
}

.terms-container .terms-content .terms-text.desktop .title {
  font-size: 28px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  font-family: HelveticaNeuBold;
}

.terms-container .terms-content .terms-text.desktop .text {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #898383;
}


.terms-container .cookies-button {
  text-align: center;
  text-decoration: none;
  border: none;
  color: white;
}

.terms-container .cookies-button.mobile {
  margin: 36px auto;
}

.terms-container .cookies-button.desktop {
  margin-top: 36px;
}

.terms-container .terms-btn:hover.desktop {
  background-color: #27282e !important;
}

@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .terms-container .terms-content.mobile {
    padding: 15px 130px;
  }
}

@media only screen and (min-device-width: 1440px) {
  .terms-container.desktop {
    padding-top: 80px;
  }
  .terms-container .terms-content.desktop {
    padding: 100px;
    width: 50%;
  }
}
