.cart-item {
  background-color: white;
  height: 80px;
  margin: 10px 0 15px !important;
}
.cart-item .box-info.mobile {
  padding: 0 8px 0 0 !important;
}
.cart-item .box-info.mobile .mobile-flex {
  display: flex;
  flex-direction: column;
}

.cart-item .img-item img {
  width: 100%;
  max-width: 80px;
  height: 80px;
  overflow: hidden;
  object-fit: contain;
  border-radius: 10px 0px 0px 10px;
}

.cart-item .product-name.desktop,
.cart-item .product-brand.desktop,
.cart-item .product-price.desktop {
  width: 100px;
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}
.cart-item .product-price.desktop {
    padding-left: 5px;
  }
.cart-item .product-count.list {
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  
}

.cart-item .product-name.mobile,
.cart-item .product-brand.mobile,
.cart-item .product-price.mobile {
  color: black;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  padding-left: 5px;
}

.cart-item .product-name.desktop {
  width: 100%;
}

.cart-item .product-price.desktop {
  width: 70px;
  font-weight: bold;
  text-align: center;
}

.cart-item .product-price.mobile {
  font-weight: bold;
}

.cart-item .cart-btn {
  height: 30px;
  width: 30px;
  border: 0;
  background: none !important;
  background-image: url('/misc/icons/trash_gray.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 22px 22px !important;
}

.cart-item .cart-btn.desktop:hover {
  background-image: url('/misc/icons/trash_dark.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 22px 22px !important;
}

@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .cart-item .box-info.mobile .mobile-flex {
    display: flex;
    flex-direction: row;
  }
  .cart-item .box-info.mobile {
    padding: 0 8px 0 50px !important;
  }
  .cart-item .product-price.mobile {
    font-size: 13px;
    padding-left: 35px;
  }
  .cart-item .product-name.mobile {
    font-size: 13px;
  }
}
