.admin-container {
  min-height: 100%;
  overflow: auto;
  position: relative;
}

.admin-container .admin-menu.desktop {
  padding: 150px 0 0 90px !important;
}

.admin-container .components-admin-container.desktop {
  overflow-y: auto;
  overflow-x: hidden;
}

.admin-container .logout-btn-container .logout-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 15px;
}

.admin-container .logout-btn-container .icon-logout {
  height: 18px;
  width: 18px;
}

.admin-container .logout-btn-container .logout-text {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.admin-container .logout-btn-container .logout-text {
  font-weight: 300;
  font-size: 14px;
}

/* @media (max-width: 1200px) {
  .profile-container .profile-menu.desktop {
    padding: 150px 0 0 50px !important;
  }
} */
@media only screen and (min-device-width: 1440px) {
  .admin-container .admin-menu.desktop {
    padding: 150px 0 0 100px !important;
  }
}
