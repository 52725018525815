.brands-container {
  
  height: 100%;
}

.brands-container .brands-title {
  padding-left: 3rem !important;
}
.brands-container .brands-title .title-blue {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.brands-container .brands-title .title-blue.desktop {
  font-size: 32px;
}

.brands-container .brands-title .title-blue.mobile {
  font-size: 18px;
}

.brands-container .brands-title .description {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: white;
}

.brands-container .brands-title .description.desktop {
  font-size: 16px;
}

.brands-container .brands-title .description.mobile {
  font-size: 14px;
}
.brands-container .brands-content .brands-list {
  position: relative;
}
.brands-container .brands-content .slider-brands {
  margin-bottom: -7px
}
.brands-container .brands-content .brands-list.mobile {
  padding-top: 10px;
}
.brands-container .brands-content .brands-list.mobile .photo-img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.brands-container .brands-content .brands-list.desktop .photo-img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.brands-container .brands-content .brands-list .logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}
.brands-container .brands-content .brands-list.mobile .logo {
  height: 50%;
  width: auto;
  object-fit: cover;
}
.brands-container .brands-content .brands-list.desktop .logo {
  height:60%;
  width: auto;
  object-fit: cover;
}
.brands-container .brands-content .brands-list .logo:hover {
  display: inline;
  opacity: 1;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.brands-container .brands-info.mobile {
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
}
.brands-container .brands-info.desktop {
  width: 100%;
  height: 450px;
}
.brands-container .brands-info.mobile button {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.brands-container .brands-info.desktop button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-top: 3rem;
}
.brands-container .brands-info button {
  background-color: #f3f2f9;
  color: #2b2d36;
}
.brands-container .brands-info p {
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
}
.brands-container .brands-info.mobile p {
  font-size: 14px;
  margin-bottom: 3rem;
}
.brands-container .brands-info.desktop {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brands-container .slick-prev {
  left: 0px !important;
  z-index: 1;
}

.brands-container .slick-next {
  right: 0px !important;
  z-index: 1;
}

.brands-container .slick-prev,
.brands-container .slick-prev:before,
.brands-container .slick-next,
.brands-container .slick-next:before,
.brands-container .slick-next:focus,
.brands-container .slick-prev:focus,
.brands-container .slick-next:hover,
.brands-container .slick-prev:hover {
  color: white !important;
  opacity: 1 !important;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  opacity: 1;
  /* background: whitesmoke; */
  transition: all 0.3s ease-in-out;
  content: '' !important;
}

.brands-container .slick-prev:before {
  background-image: url('/misc/icons/arrow_left_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.brands-container .slick-next:before {
  background-image: url('/misc/icons/arrow_right_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.brands-content .custom-paging {
  background-color: transparent;
  border: 1px #27282E solid;
}

.brands-content .slick-dots {
  display: flex !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;
}

.brands-content.mobile .slick-dots {
  bottom: 30px !important;
  /* left: 45% !important; */
  display: table !important;
  margin: 0 auto !important;
}

.brands-content.mobile .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}

.brands-content.mobile .slick-dots li div {
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
}

.brands-content .slick-dots li.slick-active div {
  background-color: #27282E !important;
}

.brands-content .slick-dots li div {
  background-color: transparent !important;
}
.brands-content .custom-paging:hover {
  background-color: #27282E !important;
}

.brand-detail-description strong, .brand-detail-description b {
  font-weight: bold;
}

.brand-detail-description em, .brand-detail-description i {
  font-style: italic;
}

@media only screen and (max-width: 800px) {
  .brands-container .brands-title {
    padding-left: 4% !important;
  }
}

@media only screen and (max-width: 549px) {
  .brands-container .brands-title {
    padding-left: 8% !important;
  }
}