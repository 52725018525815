.side-filters-container {
  position: relative;
  padding-left: 0.75rem !important;
}

.side-filters-container .close-filters {
  position: absolute;
  top: 25px;
  right: -15px;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  opacity: 0.2;
}

/* ----  TREEVIEW  ----- */
.MuiTypography-body1 {
  font-family: Arial, Helvetica, sans-serif !important;
}
/* ---- first node ----- */

.side-filters-container .first-node > li > .MuiTreeItem-content .MuiTreeItem-label {
  margin: 10px 0;
  color: #a9a9ab;
  border-bottom: 2px solid #a9a9ab;
  padding-left: 20px !important;
  background-color: transparent;
  width: 80%;
  font-size: 1.3rem !important;
}
.side-filters-container .first-node > li > .MuiTreeItem-content .MuiTreeItem-label:hover {
  color: black;
  font-weight: bold;
  border-bottom: 3px solid #53bfd4;
}

.side-filters-container .first-node > .Mui-expanded > .MuiTreeItem-content .MuiTreeItem-label {
  color: black;
  font-weight: bold;
  border-bottom: 3px solid #53bfd4;
}

.side-filters-container .first-node > .Mui-expanded > ul {
  min-height: 0px;
  border-bottom: 3px solid #53bfd4;
  width: 81%;
  margin-bottom: 15px;
}
/* ---- second node ----- */
.side-filters-container .first-node > .Mui-expanded > ul .MuiTreeItem-label {
  text-decoration: none;
  background-color: transparent;
  margin-bottom: 13px;
  text-transform: none;
  font-size: 16px !important;
  font-family: Arial, Helvetica, sans-serif !important;
  color: black;
}
.side-filters-container .first-node > .Mui-expanded > ul .MuiTreeItem-label:hover {
  font-weight: bold;
  color: #53bfd4;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label,
.side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded .MuiTreeItem-label {
  color: #53bfd4;
  font-weight: bold;
}

/* ---- third node ----- */
.side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .MuiTreeItem-label {
  font-size: 15px !important;
  color: black;
  border: none;
  text-transform: none;
  font-weight: 300;
  /* font-family: Arial, Helvetica, sans-serif !important; */
  /* margin-left: -45px; */
}
.side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .MuiTreeItem-label:hover {
  color: #53bfd4;
  /* font-weight: bold; */
}
.side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .Mui-expanded .MuiTreeItem-label {
  font-size: 15px;
  color: #53bfd4;
  border: none;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label {
  font-size: 15px;
  color: #53bfd4;
  border: none;
}
/* ---- fourth node ----- */
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  > .MuiCollapse-wrapper
  > .MuiCollapse-wrapperInner
  > li
  > .MuiTreeItem-content {
  align-items: flex-start;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  > .MuiCollapse-wrapper
  > .MuiCollapse-wrapperInner
  > li
  > .MuiTreeItem-content::before {
  content: '\2024';
  display: inline-block;
  color: black;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  > .MuiCollapse-wrapper
  > .MuiCollapse-wrapperInner
  > li
  > .MuiTreeItem-content:hover::before {
  content: '\2024';
  display: inline-block;
  color: #53bfd4;
}
.side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .Mui-expanded > ul {
  align-items: baseline;
  vertical-align: middle;
  padding-left: 20px;
}
.side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .Mui-expanded > ul .MuiTreeItem-label {
  font-size: 14px !important;
  color: black;
  border: none;
  text-transform: none;
  font-family: Arial, Helvetica, sans-serif !important;
  margin-top: 5px;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .MuiTreeItem-label:hover {
  color: #53bfd4;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .MuiTreeItem-iconContainer {
  width: 0;
  margin-right: 0px;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > .MuiTreeItem-content
  .MuiTreeItem-label {
  font-size: 14px;
  color: #53bfd4;
  border: none;
}
.side-filters-container
  .first-node
  > .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > ul
  .Mui-expanded
  > .MuiTreeItem-content::before {
  content: '\2024';
  display: inline-block;
  color: #53bfd4 !important;
}

/* --------------- */

@media only screen and (min-device-width: 1440px) {
  .side-filters-container {
    padding: 0 17% 0 2% !important;
  }
  /* ---- first node ----- */
  .side-filters-container .first-node > li > .MuiTreeItem-content {
    margin: 10px 0 15px;
    height: 35px;
    width: 300px;
  }
  .side-filters-container .first-node > .Mui-expanded > ul {
    width: 242px;
  }
  /* ---- second node ----- */
  .side-filters-container .first-node > .Mui-expanded > ul .MuiTreeItem-label {
    /* margin-left: -28px; */
    margin-bottom: 14px;
    font-size: 19px;
  }

  /* ---- third node ----- */
  .side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .MuiTreeItem-label {
    font-size: 17px;
    border: none;
    /* margin-left: -45px; */
  }
  .side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .Mui-expanded .MuiTreeItem-label {
    font-size: 17px;
  }
  .side-filters-container
    .first-node
    > .Mui-expanded
    > ul
    .Mui-expanded
    > ul
    .MuiTreeItem-root.Mui-selected
    > .MuiTreeItem-content
    .MuiTreeItem-label {
    font-size: 17px;
  }
  /* ---- fourth node ----- */

  .side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .Mui-expanded > ul {
    padding-left: 20px;
  }
  .side-filters-container .first-node > .Mui-expanded > ul .Mui-expanded > ul .Mui-expanded > ul .MuiTreeItem-label {
    font-size: 16px;
    padding-left: 5px;
  }

  .side-filters-container
    .first-node
    > .Mui-expanded
    > ul
    .Mui-expanded
    > ul
    .Mui-expanded
    > ul
    > .MuiCollapse-wrapper
    > .MuiCollapse-wrapperInner
    > li {
    /* margin-left: -25px; */
    margin-top: -5px;
  }
  .side-filters-container
    .first-node
    > .Mui-expanded
    > ul
    .Mui-expanded
    > ul
    .Mui-expanded
    > ul
    .Mui-expanded
    > .MuiTreeItem-content
    .MuiTreeItem-label {
    font-size: 16px;
  }
}

.btn-filters {
  margin-left: -30px;
}

@media only screen and (max-width: 1240px) {
  .side-filters-container {
    padding: 0 10% 0 0 !important;
}
  .side-filters-container .first-node > li > .MuiTreeItem-content .MuiTreeItem-label {
    width: 70%;
    font-size: 1rem !important;
  }
  .side-filters-container .first-node > .Mui-expanded > ul {
    width: 210px;
  }
  .side-filters-container .first-node > .Mui-expanded > ul .MuiTreeItem-label {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .side-filters-container .first-node > li > .MuiTreeItem-content .MuiTreeItem-label {
    font-size: 1.1rem !important;
    width: 75%;
  }
  .side-filters-container .first-node > li > .MuiTreeItem-content {
    width: 280px !important;
  }
  .side-filters-container .first-node > .Mui-expanded > ul {
    width: 210px;
  }
}
@media only screen and (max-width: 800px) {
  .side-filters-container {
    padding: 0 !important;
}
  .side-filters-container .categories-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}


