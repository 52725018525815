.order-item-container .full-screen-detail {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
}

.order-item-container .full-screen-detail.desktop {
  padding: 50px 14%;
}

.order-item-container .full-screen-detail.mobile {
  padding: 0;
}

.order-item-container .full-screen-detail .detail-content {
  height: 90%;
  width: 100%;
  overflow: scroll;
}

.order-item-container .full-screen-detail .detail-content.desktop #capture {
  padding: 50px;
}

.order-item-container .full-screen-detail .detail-content.mobile #capture {
  padding: 50px 25px;
}

.order-item-container .full-screen-detail .close-btn {
  border-radius: 50%;
  transform: rotate(45deg);
}

.order-item-container .full-screen-detail .close-btn.desktop {
  position: absolute;
  top: 40px;
  right: 13%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.order-item-container .full-screen-detail .close-btn.mobile {
  position: absolute;
  top: 50px;
  right: 25px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.order-item-container .full-screen-detail .detail-content .title {
  color: black;
  font-size: 14px;
  font-weight: bolder;
  margin-left: 16px;
}

.order-item-container .full-screen-detail .detail-content .order-details-header {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 8px !important;
}

.order-item-container .full-screen-detail .detail-content .data-container {
  border: 1px solid black;
  padding: 18px 0px 18px 18px;
  height: 160px;
}

.order-item-container .full-screen-detail .detail-content .data-container .title {
  color: black;
  font-size: 14px;
  font-weight: bolder;
  margin: 0;
}

.order-item-container .full-screen-detail .detail-content .data-container .address-info,
.order-item-container .full-screen-detail .detail-content .data-container .summary-info,
.order-item-container .full-screen-detail .detail-content .data-container .status-info {
  position: relative;
  margin-top: 16px !important;
}

.order-item-container .full-screen-detail .detail-content .data-container .summary-info .info-row {
  height: 18px;
}

.order-item-container .full-screen-detail .detail-content .data-container .more-info,
.order-item-container .full-screen-detail .detail-content .data-container .more-info {
  position: absolute;
  bottom: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  font-weight: 300;
  font-size: 10px !important;
  color: #a9a9ab;
}

.order-item-container .full-screen-detail .detail-content .data-container .address-info .detail-info,
.order-item-container .full-screen-detail .detail-content .data-container .summary-info .detail-info,
.order-item-container .full-screen-detail .detail-content .data-container .status-info .detail-info {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  color: black;
}

.order-item-container .full-screen-detail .detail-content .data-container .summary-info .detail-info.title {
  text-align: start;
  width: 75px;
  margin-right: 8px;
}


.order-item-container .full-screen-detail .detail-content .additional-info {
  border-top: 1px solid black;
  border-bottom: 1px solid #a9a9ab;
  padding: 24px 0px 24px 0px;
  margin-bottom: 24px !important;
}

.order-item-container .full-screen-detail .detail-content .additional-info .articles-info {
  color: black;
  font-size: 13px;
  line-height: 2;
}

.order-item-container .full-screen-detail .detail-content .additional-info .articles-info .articles-info-title {
  width: 85px !important;
}

.order-item-container .full-screen-detail .detail-content .additional-info .articles-info .articles-info-data {
  width: 55px !important;
  justify-content: flex-end;
}

.order-item-container .full-screen-detail .detail-content .additional-info .prices-info {
  color: black;
  font-size: 13px;
  /* line-height: 3; */
}

.order-item-container .full-screen-detail .detail-content .additional-info .prices-info .prices-info-title {
  width: 85px !important;
  justify-content: flex-end;
}

.order-item-container .full-screen-detail .detail-content .additional-info .prices-info .prices-info-data {
  width: 95px !important;
  justify-content: flex-end;
}

.order-item-container .full-screen-detail .detail-content .additional-info .prices-info .prices-info-title.total-title {
  font-size: 15px;
}

.order-item-container .full-screen-detail .detail-content .additional-info .prices-info .prices-info-data.total-data {
  font-size: 16px;
  margin-top: 24px;
}

.order-item-container .full-screen-detail .detail-content .footer-info {
  font-size: 10px;
  margin: 0;
  text-align: center;
}

.order-item-container .full-screen-detail .detail-content .pdf-btn:hover {
  background-color: #27282e !important;
}

.order-item-container .full-screen-detail .detail-content .repeat-btn:hover {
  background-color: #53bfd4 !important;
}

.order-item-container .full-screen-detail .detail-content.desktop {
  padding: 50px 50px;
}


@media only screen and (min-width: 801px) and (max-width: 1100px) {
  .order-item-container .full-screen-detail.desktop {
    padding: 50px 7% !important;
  }
  .order-item-container .full-screen-detail .detail-content.desktop {
    padding: 45px 45px !important;
  }
  .order-item-container .full-screen-detail .close-btn.desktop {
    right: 5.3% !important;
  }
}

@media only screen and (min-device-width: 1440px) {  
  .order-item-container .full-screen-detail.desktop {
    padding: 50px 14%;
  }
  .order-item-container .full-screen-detail .close-btn.desktop {
    right: 13%;
  }
}

@media only screen and (min-device-width: 1700px) {
  .order-item-container .full-screen-detail .detail-content.desktop {
    padding: 75px 75px;
  }
  .order-item-container .full-screen-detail.desktop {
    padding: 50px 16%;
  }
  .order-item-container .full-screen-detail .close-btn.desktop {
    right: 15.3%;
  }
}
