.home-container {
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}


.breaking.mobile {
  padding-top: 80px;
}

.no-breaking.mobile {
  padding-top: 30px;
}
.breaking.desktop {
  padding-top: 40px;
}

.no-breaking.desktop {
  padding-top: 0px;
}

.home-container.desktop {
  padding-top: 80px;
}

.home-container.mobile {
  padding-top: 70px;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
}
@media only screen and (min-device-width: 1440px) {
  .home-container.desktop {
    padding-top: 80px;
  }
}
@media only screen and (min-device-width: 1700px) {
  .home-container.desktop {
    padding-top: 80px;
    /* padding-left: calc(50% - 960px);
    padding-right: calc(50% - 960px); */
  }
}
