.back-btn-container .back-btn.desktop {
  position: absolute;
  top: 91px;
  left: 0px;
  margin: 42px 35px;
}

.back-btn-container .back-btn.mobile {
  position: absolute;
  top: 100px;
  left: 0px;
  margin: 15px;
}

.back-btn-container .icon-back.mobile {
  height: 18px;
  width: 18px;
}

.back-btn-container .icon-back.desktop {
  height: 20px;
  width: 20px;
}

.back-btn-container .back-text {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.back-btn-container .back-text.mobile {
  font-weight: 300;
  font-size: 14px;
}

.back-btn-container .back-text.desktop {
  font-weight: 400;
  font-size: 16px;
}
@media only screen and (min-device-width: 1440px) {
  .back-btn-container .back-btn.desktop {
    top: 90px;
  }
}

@media not all and (min-resolution:.001dpcm) { 
  @media {
    .back-btn-container .back-btn.desktop {
      top: 80px;
    }     
  }
}