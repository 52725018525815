.footer-container {
  height: 80px;
  width: 100%;
  position: relative;
  margin-top: -80px;
  z-index: 3;
}

.footer-container.mobile { 
  height: auto;
}

.footer-container .footer-content-mobile {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.footer-container a,
.footer-container a:hover{
  text-decoration: none
}
.footer-container a.desktop{
  margin-bottom: 3px;
}

.footer-container .footer-logo {
  width: 75px;
}

.footer-container .footer-info span,
.footer-container .rights-info {
  font-size: 1.2rem;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: white;
}

.footer-container .footer-info .separator {
  width: 1px;
  background-color: white;
  height: 14px;
  margin-left: 18px;
  margin-right: 18px;
}

.footer-container .footer-info i {
  margin-right: 2px;
}

.footer-container .footer-info .footer-icon {
  height: 15px;
  width: 15px;
}

.footer-container .footer-info .links-info span:hover {
  color: #53bfd4 !important;
}

@media only screen and (min-width: 1220px) and (max-width: 1400px) {
  .footer-container .footer-info .separator { 
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media only screen and (max-width: 1220px) {
  .footer-container .footer-info .links-info a {
    text-align: center;
    line-height: 1;
  }
}