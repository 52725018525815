/*---- modal ----*/
.modal-backdrop {
  z-index: 1 !important;
}

.doubleModal-box .modal {
  text-align: center;
}

.doubleModal-box .modal.fade .double-modal-dialog.mobile {
  transform: translate3d(0, 0vh, 0) !important;
}

.doubleModal-box .modal.show .double-modal-dialog.mobile {
  transform: translate3d(0, 0, 0) !important;
}

.doubleModal-box .double-modal-dialog.mobile {
  max-width: 100% !important;
}

.doubleModal-box .double-modal-dialog.desktop {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.doubleModal-box .double-modal-content {
  justify-content: center !important;
  height: auto;
  width: 100% !important;
}

.doubleModal-box .double-modal-content.mobile {
  border-top-right-radius: 21px !important;
  border-top-left-radius: 21px !important;
  height: 60vh;
  width: 100% !important;
}

.doubleModal-box .double-modal-body {
  width: 100%;
  height: auto;
}

.doubleModal-box .double-modal-body.desktop {
  position: relative;
  padding: 80px 200px 80px 200px !important;
}

.doubleModal-box .double-modal-body.mobile {
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}

.doubleModal-box .double-modal-body .main-btn:hover {
  background-color: #27282e !important;
}

.doubleModal-box .double-modal-body .secondary-btn:hover {
  background-color: #53bfd4 !important;
}

.doubleModal-box .modal {
  margin: 0;
  padding: 0;
  border: 0;
}

.modal-error .modal {
  z-index: 1051 !important;
}

.doubleModal-box .modal.mobile {
  top: 55% !important;
}

.doubleModal-box .modal.desktop {
  top: 35% !important;
  overflow: visible;
}

.doubleModal-box .fade {
  transition: opacity 0.3s linear !important;
}

#doubleModal .btn-close {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
  height: 40px;
  width: 40px;
}

#doubleModal .btn-close img {
  width: 35px;
  height: 35px;
}

#modalSimple .text-box.mobile .img-middle {
  width: 60px;
  height: 60px;
}

#modalSimple .text-box.desktop .img-middle {
  width: 80px;
  height: 80px;
}

#doubleModal .text-box.mobile {
  padding: 3rem 3rem 1rem;
}

#doubleModal .text-box.desktop {
  width: 440px;
}

#doubleModal .text-box.mobile span {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
}

#doubleModal .text-box.mobile .title {
  font-size: 18px;
  font-weight: bolder;
}

#doubleModal .text-box.desktop .title {
  font-size: 16px;
  font-weight: bolder;
}

#doubleModal .text-box.mobile .description {
  font-size: 12px;
}

#doubleModal .text-box.desktop .description {
  font-size: 14px;
}

/* <----IPHONE X ------> */
@media only screen and (device-width: 375px) and (device-height: 812px) {
  .doubleModal-box .modal.mobile {
    top: 60% !important;
  }
}
/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .doubleModal-box .modal.mobile {
    top: 40% !important;
  }
}
/* <----- iphone 6/7/8 ----> */
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .doubleModal-box .modal.mobile {
    top: 50% !important;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1095px) {
  #inputModal .text-box.mobile {
    padding: 3rem 6rem 1rem;
  }
}
@media only screen and (min-device-width: 1440px) {
}
@media only screen and (min-device-width: 1700px) {
}
@media only screen and (min-device-width: 2200px) {
}
