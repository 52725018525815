.addresses-container.mobile {
  padding: 24px !important;
}

.addresses-container.desktop {
  padding: 80px 100px !important;
  height: 100%;
}

.addresses-container .add-info {
  color: #a9a9ab;
  font-size: 11px;
  margin-bottom: 8px;
}

.addresses-container .add-info a {
  color: #53bfd4 !important;
  text-decoration: underline !important;
}

.addresses-container .add-info a:hover {
  color: black !important;
  text-decoration: underline !important;
}

.addresses-container .add-btn {
  height: 35px;
  width: 100px;
  border: 0;
  /* border-radius: 10px; */
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: white;
}
.addresses-container .add-btn img {
  width: 18px;
  height: 18px;
}

.addresses-container .empty-addresses-container .empty-addresses-parts .text-empty {
  white-space: pre-wrap;
}

.empty-addresses-container .empty-addresses-parts .box-empty.mobile img {
  height: 100px;
  width: 100px;
}

.empty-addresses-container .empty-addresses-parts .box-empty.desktop img {
  height: 120px;
  width: 120px;
}

.empty-addresses-container .empty-addresses-parts .box-empty.desktop span {
  margin-top: 30px;
  margin-bottom: 13px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  font-family: HelveticaNeuBold;
  color: #747474;
}

.empty-addresses-container .empty-addresses-parts .box-empty.mobile span {
  margin-top: 30px;
  margin-bottom: 13px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  font-family: HelveticaNeuBold;
  color: #747474;
}

@media only screen and (min-device-width: 1440px) {
  .empty-addresses-container .empty-addresses-parts.desktop {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .empty-addresses-container {
    padding-bottom: 90px;
  }
}
