.success-detail-container {
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}

.success-detail-container.desktop {
  padding-top: 80px;
}

.success-detail-container.mobile {
  padding-top: 100px;
  min-height: 95%;
}
.success-detail-container .success-detail-parts.desktop {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15% 25px !important;
}
.success-detail-container .success-detail-parts.mobile {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 100px 20px !important;
}
.success-detail-container .box-empty.mobile img {
  height: 120px;
  width: 120px;
}
.success-detail-container .box-empty.desktop img {
  height: 150px;
  width: 150px;
}
.success-detail-container .box-empty.desktop h4 {
  color: #2b2d36;
  margin-top: 30px;
  margin-bottom: 13px;
}
.success-detail-container .box-empty.mobile h4 {
  color: #2b2d36;
  margin-top: 30px;
  margin-bottom: 13px;
}
.success-detail-container .box-empty.desktop span {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}
.success-detail-container .box-empty.mobile span {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}

@media only screen and (min-device-width: 1440px) {
  .success-detail-container .success-detail-parts.desktop {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 20% 25px !important;
  }
  .success-detail-container {
    padding-bottom: 80px;
  }
}
