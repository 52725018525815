.profile-menu-content.mobile {
  padding-top: 150px;
  font-size: 14px;
}

.profile-menu-content.desktop {
  font-size: 16px;
}

.profile-menu-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.profile-menu-content ul li {
  cursor: pointer;
}

.profile-menu-content ul li:hover {
  color: #53bfd4;
}

.profile-menu-content li img {
  height: 20px;
  width: 20px;
  margin-right: 16px;
}

.profile-menu-content .menu-mobile {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
}

.profile-menu-content .menu-mobile::-webkit-scrollbar {
  display: none;
}

.profile-menu-content .menu-mobile li {
  display: inline-block;
  text-align: center;
  padding: 0 14px 0 14px;
  text-decoration: none;
}

.profile-menu-content .menu-mobile .selected {
  border-bottom: 2px solid #53bfd4;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .profile-menu-content .menu-mobile li {
    padding: 0 64px 0 84px;
    padding-bottom: 0px;
    font-size: 15px;
  }
}
