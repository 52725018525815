/*---- modal ----*/
.modal-backdrop {
  z-index: 1 !important;
}

.modalPromotions-box .modal {
  text-align: center;
}

.modalPromotions-box .modal.fade .simple-modal-image-dialog.mobile {
  transform: translate3d(0, 0vh, 0) !important;
}

.modalPromotions-box .modal.show .simple-modal-image-dialog.mobile {
  transform: translate3d(0, 0, 0) !important;
}

.modalPromotions-box .simple-modal-image-dialog.mobile {
  max-width: 100% !important;
}

.modalPromotions-box .simple-modal-image-dialog.desktop {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  max-width: 600px;
}

.modalPromotions-box .simple-modal-image-content {
  justify-content: center !important;
  height: auto;
  width: 100% !important;
}

.modalPromotions-box .simple-modal-image-content.mobile {
  border-top-right-radius: 21px !important;
  border-top-left-radius: 21px !important;
  height: 60vh;
  width: 100% !important;
}
.modalPromotions-box .modal.desktop .simple-modal-image-content img {
  height: 300px;
  width: 80% !important;
  margin: 0 auto;
}
.modalPromotions-box .modal.mobile .simple-modal-image-content img {
  height: auto;
  width: 80% !important;
  margin: 0 auto;
}

.modalPromotions-box .simple-modal-image-content.mobile img {
  width: 100% !important;
}
.modalPromotions-box .simple-modal-image-promos {
  justify-content: center !important;
  height: auto;
  width: 100% !important;
}

.modalPromotions-box .simple-modal-image-promos.mobile {
  border-top-right-radius: 21px !important;
  border-top-left-radius: 21px !important;
  padding-bottom: 20px;
  width: 100% !important;
}

.modalPromotions-box .simple-modal-image-body {
  width: 100%;
  height: auto;
}

.modalPromotions-box .simple-modal-image-body.desktop {
  border-radius: 6px;
  position: relative;
  padding: 10px 100px 0px !important;
}

.modalPromotions-box .simple-modal-image-body.mobile {
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}

.modalPromotions-box .simple-modal-image-body .ok-btn:hover {
  background-color: #27282e !important;
}

.modalPromotions-box .modal {
  margin: 0;
  padding: 0;
  border: 0;
}

.modal-error .modal {
  z-index: 1051 !important;
}

.modalPromotions-box .modal.mobile {
  top: 5% !important;
}

.modalPromotions-box .modal.desktop {
  top: 10% !important;
  overflow: visible;
}

.modalPromotions-box .fade {
  transition: opacity 0.3s linear !important;
}
.slider-promos a:hover {
    text-decoration: none !important;
}

#modalPromotions .btn-close {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
  height: 40px;
  width: 40px;
}

#modalPromotions .btn-close img {
  width: 35px;
  height: 35px;
}

#modalsimple .text-box.mobile .img-middle {
  width: 60px;
  height: 60px;
}

#modalsimple .text-box.desktop .img-middle {
  width: 80px;
  height: 80px;
}

#modalPromotions .text-box.mobile {
  padding: 3rem 3rem 1rem;
}

#modalPromotions .text-box.desktop {
  margin: 50px 30px 0;
}

#modalPromotions .text-box.mobile span {
  /* font-size: 20px; */
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
}

#modalPromotions .text-box.mobile .anonymous-logo {
  width: 70%;
  height: auto;
  object-fit: cover;
  margin-bottom: 12px;
}

#modalPromotions .text-box.desktop .anonymous-logo {
  width: 55%;
  height: auto;
  object-fit: cover;
  margin-bottom: 32px;
}

#modalPromotions .text-box.mobile .title {
  font-size: 16px;
  font-weight: bolder;
}

#modalPromotions .text-box.desktop .title {
  font-size: 18px;
  font-weight: bolder;
}

#modalPromotions .text-box.mobile .description {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 24px !important;
}

#modalPromotions .text-box.desktop .description {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 24px !important;
}
#modalPromotions .retry-btn:hover {
  background-color: #2b2d36 !important;
}

.simple-modal-image-promos .slick-prev,
.simple-modal-image-promos .slick-prev:before,
.simple-modal-image-promos .slick-next,
.simple-modal-image-promos .slick-next:before,
.simple-modal-image-promos .slick-next:focus,
.simple-modal-image-promos .slick-prev:focus,
.simple-modal-image-promos .slick-next:hover,
.simple-modal-image-promos .slick-prev:hover {
  opacity: 1 !important;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  opacity: 1;
  /* background: #27282e; */
  transition: all 0.3s ease-in-out;
  content: '' !important;
}

.simple-modal-image-promos.desktop .slick-prev {
  left: 15px !important;
  z-index: 1;
}

.simple-modal-image-promos.desktop .slick-next {
  right: 15px !important;
  z-index: 1;
}
.simple-modal-image-promos.mobile .slick-prev {
  left: 0px !important;
  z-index: 1;
}

.simple-modal-image-promos.mobile .slick-next {
  right: 0px !important;
  z-index: 1;
}

.simple-modal-image-promos .slick-prev:before {
  background-image: url('/misc/icons/arrow_left_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.simple-modal-image-promos .slick-next:before {
  background-image: url('/misc/icons/arrow_right_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}
.simple-modal-image-promos .title-promo.desktop {
  font-size: 22px;
}
.simple-modal-image-promos .title-promo.mobile {
  font-size: 17px;
}


@media only screen and (min-width: 748px) and (max-width: 1120px) {
  .modalPromotions-box .modal.mobile {
    top: 10% !important;
  }
  .modalPromotions-box .simple-modal-image-content.mobile img {
    height: auto;
    width: 80% !important;
    margin: 0 auto;
  }
  #modalPromotions .text-box.mobile .anonymous-logo {
    width: 50% !important;
    height: auto;
    object-fit: cover;
    margin-bottom: 12px;
  }
}


/* <----IPHONE X ------> */
@media only screen and (device-width: 375px) and (device-height: 812px) {
  .modalPromotions-box .modal.mobile {
    top: 10% !important;
  }
  .modalPromotions-box .simple-modal-image-promos.mobile {
    min-height: 440px;
  }
}

/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .modalPromotions-box .modal.mobile {
    top: 0% !important;
  }
}

/* <----- iphone 6/7/8 ----> */
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .modalPromotions-box .modal.mobile {
    top: 0% !important;
  }
 
}
