.address-user-container {
    padding: 21px 29px 21px 29px;
    color: #26282e;
}

.address-user-container.mobile {
    font-size: 14px;
}

.address-user-container.desktop {
    font-size: 14px;
}

.address-user-container .address-user-title.desktop,
.address-user-container .address-user-title.mobile {
    margin-bottom: 11px !important;
    font-family: HelveticaNeuBold;
}

.address-user-container .address-user-info.desktop,
.address-user-container .address-user-info.mobile {
    color: #26282e;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
}

.address-user-container .actions-container.mobile button {
    height: 28px;
    width: 28px;
    border: 0;
}
  
.address-user-container .actions-container.mobile button img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}
  
.address-user-container .actions-container.desktop button {
    height: 32px;
    width: 32px;
    border: 0;
}
  
.address-user-container .actions-container.desktop button img {
    width: 21px;
    height: 21px;
    object-fit: contain;
}