.form-address-container {
  border-radius: 6px;
}

.form-address-container .form-address-title.mobile {
  height: 40px;
  padding-left: 24px;
  border-radius: 6px 6px 0 0;
}

.form-address-container .form-address-title.desktop {
  height: 50px;
  padding-left: 40px;
  border-radius: 6px 6px 0 0;
}

.form-address-container .form-address-title.mobile img {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.form-address-container .form-address-title.desktop img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.form-address-container .form-address-title input {
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0 !important;
  padding-left: 0 !important;
  color: white;
}

.form-address-container.mobile,
.form-address-container .form-address-title.mobile input,
.form-address-container .form-address-inputs.mobile input {
  font-size: 14px;
}

.form-address-container.desktop,
.form-address-container .form-address-title.desktop input,
.form-address-container .form-address-inputs.desktop input {
  font-size: 16px;
}

.form-address-container .form-address-inputs {
  padding: 16px;
}

.form-address-container .form-address-inputs input {
  height: 40px;
  padding-left: 12px !important;
  background-color: transparent;
  border: 1px solid slategray;
  border-radius: 0 !important;
  color: slategray;
}

.form-address-container .form-address-inputs input::placeholder {
  color: slategray;
}

.form-address-container .form-address-title input.is-invalid {
  border: none;
  border-bottom: 1px solid red;
}

.form-address-container .form-address-inputs input.is-invalid {
  border: 1px solid red;
}

.form-address-container .save-btn {
  height: 35px;
  width: 100px;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.form-address-container .save-btn:disabled {
  opacity: 0.3;
}
