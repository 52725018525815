.promotions-container {
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}

.promotions-container .search-box.desktop {
  padding: 0 400px 0 80px;
}
.promotions-container .search-box.mobile {
  padding: 0 15px;
  font-size: 12px !important;
}

.promotions-container .pvpr-info {
  color: #a9a9ab;
  font-size: 11px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.promotions-container .pvpr-info.desktop {
  padding: 30px 5% !important;
}

.promotions-container .pvpr-info.mobile {
  padding: 0 15px !important;
}

@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .promotions-container .search-box.mobile {
    padding: 0 250px 0 45px;
  }

  .promotions-container .pvpr-info.mobile {
    padding: 0 25px !important;
  }
}
@media only screen and (min-device-width: 1440px) {
  .promotions-container .search-box.desktop {
    padding: 0 650px 0 115px;
  }

  .promotions-container .pvpr-info.desktop {
    padding: 0 650px 0 100px !important;
  }
}

