@font-face {
  font-family: HelveticaNeuBold;
  src: local(HelveticaNeuBold), url('./utils/fonts/Helvetica Neu Bold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

html,
body,
#root,
#root > div {
  background-color: #27282e;
}
html,
body,
#root {
  font-family: Arial, Helvetica, sans-serif;
}


/*          NEW COLORS          */
.dbs-black {
  color: #000000 !important;
}

.dbs-white {
  color: #ffffff !important;
}

.dbs-blue {
  color: #53bfd4 !important;
}

.dbs-gray1 {
  color: #27282e !important;
}

.dbs-gray2 {
  color: #525358 !important;
}

.dbs-gray3 {
  color: #a9a9ab !important;
}

.dbs-gray4 {
  color: #e3e2e4 !important;
}

.dbs-gray5 {
  color: #f5f4fb !important;
}

.bg-dbs-black {
  background-color: #000000 !important;
}

.bg-dbs-white {
  background-color: #ffffff !important;
}

.bg-dbs-blue {
  background-color: #53bfd4 !important;
}

.bg-dbs-gray1 {
  background-color: #27282e !important;
}

.bg-dbs-gray2 {
  background-color: #525358 !important;
}

.bg-dbs-gray3 {
  background-color: #a9a9ab !important;
}

.bg-dbs-gray4 {
  background-color: #e3e2e4 !important;
}

.bg-dbs-gray5 {
  background-color: #f5f4fb !important;
}
/* ---------------------------- */

.main-blue {
  color: #53bfd4 !important;
}
.light-blue {
  color: #d6edf3 !important;
}

.white {
  color: white !important;
}

.black {
  color: black !important;
}

.red {
  color: #e2001a !important;
}
.grey {
  color: #898383 !important;
}

.light-text {
  color: #708090 !important;
}

.dark-text {
  color: #27282e !important;
}

.bg-main-b2b {
  background-color: #2f323c !important;
}
.bg-grey-b2b {
  background-color: #1f222a !important;
}
.bg-grey {
  background-color: #898383 !important;
}

.bg-dark-grey-b2b {
  background-color: #1c1f25 !important;
}

.bg-secondary-b2b {
  background-color: #53bfd4 !important;
}

.bg-dark-b2b {
  background-color: #27282e !important;
}

.bg-light-b2b {
  background-color: #f5f4fb !important;
}
.bg-light-grey-b2b {
  background-color: #f0f0f7 !important;
}
.h-80 {
  height: 80% !important;
}
.h-20 {
  height: 20% !important;
}

button:focus,
input:focus,
textarea:focus,
select:focus,
div {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-transparent.mobile {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  height: 35px;
  font-size: 14px;
  width: 150px;
  padding: 0;
}
.btn-modal.mobile {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  height: 35px;
  font-size: 14px;
  width: 150px;
  padding: 0;
}
.btn-transparent {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  padding: 0;
}
.btn-transparent.desktop {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  height: 45px;
  font-size: 14px;
  width: 200px;
  padding: 0;
}
.btn-modal.desktop {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  height: 40px;
  font-size: 14px;
  width: 150px;
  padding: 0;
}
.animated {
  animation-duration: 0.7s;
  animation-fill-mode: both;
}
