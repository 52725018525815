/*---- modal ----*/
.modal-backdrop {
  z-index: 1 !important;
}

.registerModal-box .modal {
  text-align: center;
}

.registerModal-box .modal.fade .register-modal-dialog.mobile {
  transform: translate3d(0, 0vh, 0) !important;
}

.registerModal-box .modal.show .register-modal-dialog.mobile {
  transform: translate3d(0, 0, 0) !important;
}

.registerModal-box .register-modal-dialog.mobile {
  max-width: 100% !important;
}

.registerModal-box .register-modal-dialog.desktop {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  max-width: 1000px;
}

.registerModal-box .register-modal-content {
  justify-content: center !important;
  height: auto;
  width: 100% !important;
}

.registerModal-box .register-modal-content.mobile {
  border-top-right-radius: 21px !important;
  border-top-left-radius: 21px !important;
  height: 60vh;
  width: 100% !important;
}

.registerModal-box .register-modal-body {
  width: 100%;
  height: auto;
}

.registerModal-box .register-modal-body.desktop {
  border-radius: 6px;
  position: relative;
  padding: 80px 200px 80px 200px !important;
}

.registerModal-box .register-modal-body.mobile {
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}

.registerModal-box .register-modal-body .btn-modal-skip {
  font-size: 14px;
  text-decoration: underline;
}

.registerModal-box .modal {
  margin: 0;
  padding: 0;
  border: 0;
}

.modal-error .modal {
  z-index: 1051 !important;
}

.registerModal-box .modal.mobile {
  top: 55% !important;
}

.registerModal-box .modal.desktop {
  top: 35% !important;
  overflow: visible;
}

.registerModal-box .fade {
  transition: opacity 0.3s linear !important;
}

#modalregister .text-box.mobile .img-middle {
  width: 60px;
  height: 60px;
}

#modalregister .text-box.desktop .img-middle {
  width: 80px;
  height: 80px;
}

#registerModal .text-box.mobile {
  padding: 3rem 3rem 1rem;
}

#registerModal .text-box.desktop {
  margin-bottom: 50px;
}

#registerModal .text-box.mobile span {
  /* font-size: 20px; */
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.48px;
  text-align: center;
}

#registerModal .text-box.mobile .title {
  font-size: 18px;
  font-weight: bolder;
}

#registerModal .text-box.desktop .title {
  font-size: 16px;
  font-weight: bolder;
}

#registerModal .text-box.mobile .description {
  font-size: 12px;
}

#registerModal .text-box.desktop .description {
  font-size: 14px;
}

/* <----IPHONE X ------> */
@media only screen and (device-width: 375px) and (device-height: 812px) {
  .registerModal-box .modal.mobile {
    top: 60% !important;
  }
}
/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .registerModal-box .modal.mobile {
    top: 40% !important;
  }
}
/* <----- iphone 6/7/8 ----> */
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .registerModal-box .modal.mobile {
    top: 50% !important;
  }
}
