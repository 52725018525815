.home-slider.mobile {
  max-height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.home-slider.mobile img {
  width: 100%;
  object-fit: cover;
}

.home-slider.desktop {
  max-height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.home-slider.desktop button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-slider .slick-prev,
.home-slider .slick-prev:before,
.home-slider .slick-next,
.home-slider .slick-next:before,
.home-slider .slick-next:focus,
.home-slider .slick-prev:focus,
.home-slider .slick-next:hover,
.home-slider .slick-prev:hover {
  color: black !important;
  opacity: 1 !important;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  content: '' !important;
}

.home-slider .slick-prev:before {
  background-image: url('/misc/icons/arrow_left_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.home-slider .slick-next:before {
  background-image: url('/misc/icons/arrow_right_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.home-slider .slick-prev {
  left: 5px !important;
  z-index: 1;
}

.home-slider .slick-next {
  right: 5px !important;
  z-index: 1;
}

.home-slider .custom-paging {
  background-color: transparent;
  border: 1px #27282E solid;
}

.home-slider .slick-dots {
  display: flex !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;
  z-index: 1
}

.home-slider.mobile .slick-dots {
  display: table !important;
  margin: 0 auto !important;
}

.home-slider.desktop .slick-dots {
  margin: 0 auto !important;
}

.home-slider.desktop .slick-dots li {
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
}

.home-slider.desktop .slick-dots li div {
  width: 15px !important;
  height: 15px !important;
  padding: 0 !important;
}

.home-slider.mobile .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}

.home-slider.mobile .slick-dots li div {
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
}

.home-slider .slick-dots li.slick-active div {
  background-color: #27282E !important;
}

.home-slider .slick-dots li div {
  background-color: transparent !important;
}

.home-slider .custom-paging:hover {
  background-color: #27282E !important;
}

.home-slider .item-content {
  position: relative;
}

.home-slider .url-button {
  position: absolute;

  color: #27282E;
}

.home-slider .url-button.mobile {
  bottom: 10px;
  right: 20px;
  align-items: center;
}

.home-slider .url-button.desktop {
  bottom: 90px;
  right: 90px;
  align-items: center;
}

.home-slider-container.slider-product .slick-dots {
  display: block !important;
  position: relative;
  z-index: 4;
  bottom: -7px;
}

@media only screen and (min-width: 748px) and (max-width: 1095px) and (orientation : portrait){
  .home-slider .url-button.mobile {
    bottom: 20px;
    right: 30px;
  }
}
@media only screen and (min-width: 748px) and (max-width: 1095px) and (orientation : landscape){
 
  .home-slider .url-button.mobile {
    bottom: 20px;
    right: 30px;
  }
}

@media only screen and (min-width: 801px){
  .home-slider.desktop {
    max-height: auto;
  }
  .home-slider.desktop img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-device-width: 1440px) {
  .home-slider.desktop {
    max-height: auto;
  }
  .home-slider.desktop img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-device-width: 1920px) {
  .home-slider.desktop {
    max-height: auto;
    margin: 0 auto;
  }
   .home-slider.desktop img {
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 800px){
  .home-slider-container.slider-product .home-slider-home .slick-dots{
    bottom: 15px;
  }
}
