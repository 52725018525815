/*---- modal ----*/
.cartModal-box .modal.fade .modal-dialog.mobile {
  transform: translate3d(0, 50vh, 0) !important;
}

.cartModal-box .modal.show .modal-dialog.mobile {
  transform: translate3d(0, 0, 0) !important;
}

.cartModal-box .modal-dialog.mobile {
  max-width: 100% !important;
}

.cartModal-box .modal-dialog.desktop {
  position: absolute;
  right: 50px;
  top: 80px;
  width: 420px;
}

.cartModal-box .modal-content {
  justify-content: center !important;
  margin: 0 auto;
  width: 100% !important;
  border-radius: 0;
}

.cartModal-box .modal-content.mobile {
  height: 50vh;
}

.cartModal-box .modal-content.mobile {
  border-top-right-radius: 21px !important;
  border-top-left-radius: 21px !important;
  height: 55vh;
  width: 100% !important;
}

.cartModal-box .modal-body {
  width: 100%;
  height: 100%;
}

.cartModal-box .modal-body.mobile {
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}

.cartModal-box .modal-body .list-product-box.mobile {
  max-height: 60%;
  min-height: 55%;
}

.cartModal-box .modal-body .list-product-box.desktop {
  max-height: 58vh;
}

.cartModal-box .modal-body .list-product-box .list-products-content {
  overflow-y: scroll;
  overflow-x: hidden;
}

.cartModal-box .modal-body .list-product-box .list-products-content::-webkit-scrollbar {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 7px;
}
.cartModal-box .modal-body .list-product-box .list-products-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.cartModal-box .modal {
  margin: 0;
  padding: 0;
  border: 0;
}

.cartModal-box .modal.mobile {
  top: 20% !important;
}
.cartModal-box .modal-content.mobile {
  height: 80vh;
}

.cartModal-box .modal-content {
  overflow-y: auto;
}

.cartModal-box .fade {
  transition: opacity 0.3s linear !important;
}

#cartModal a:hover {
  text-decoration: none;
  color: black;
}

#cartModal .cart-buttons {
  border-top: solid 1px lightgray;
}

#cartModal .cart-buttons .info-cart-articles {
  overflow: hidden;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  padding-left: 3rem !important;
}

#cartModal .cart-buttons .info-cart-articles.mobile {
  font-size: 12px;
}

#cartModal .info-cart-buttons {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.1rem !important;
}

#cartModal .info-cart-buttons.desktop {
  font-size: 14px !important;
  font-weight: 300;
}

#cartModal .info-cart-buttons .total {
  font-weight: bold;
}

#cartModal .info-cart-buttons.mobile {
  font-size: 12px !important;
  font-weight: 300;
}

#cartModal .cart-buttons .btn-cart {
  height: 39.4px;
  border: solid 1px #ffffff;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.32px;
  text-align: left;
}

#cartModal .cart-buttons .desktop .btn-cart {
  height: 42px;
  width: 150px;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 300;
}

#cartModal .cart-buttons .desktop .btn-cart.continue:hover {
  background-color: #53bfd4 !important;
}

#cartModal .cart-buttons .desktop .btn-cart.go-cart:hover {
  background-color: #27282e !important;
}

#cartModal .cart-buttons .mobile .btn-cart {
  height: 35px;
  width: 130px;
  padding: 0;
  border: 0;
  font-size: 12px;
  font-weight: 300;
}
#cartModal .box-empty.mobile {
  height: 80vh;
  width: 100%;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
  padding: 100px 10px;
}
#cartModal .box-empty.desktop {
  height: 350px;
  width: 100%;
}
#cartModal .box-empty h2 {
  margin-top: 10px;
}
#cartModal .box-empty span {
  color: #747474;
  margin-top: 5px;
  text-align: center;
}
#cartModal .box-empty .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.cart-icon {
  height: 75px;
  width: 75px;
}

/* <----IPHONE X ------>
@media only screen and (device-width: 375px) and (device-height: 812px) {
  .cartModal-box .modal.mobile {
    top: 10% !important;
  }
  .cartModal-box .modal-content.mobile {
    height: 90vh;
  }
}

/* <---iphone 5/SE ----> */
/* @media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .cartModal-box .modal.mobile {
    top: 10% !important;
  }
  .cartModal-box .modal-content.mobile {
    height: 90vh;
  }
} */

/* <----- iphone 6/7/8 ----> */
/* @media only screen and (device-width: 375px) and (device-height: 667px) {
  .cartModal-box .modal.mobile {
    top: 10% !important;
  }
  .cartModal-box .modal-content.mobile {
    height: 90vh;
  }
}  */
