.admin-menu-content.mobile {
  padding-top: 50px;
  font-size: 14px;
}

.admin-menu-content.desktop {
  font-size: 16px;
}

.admin-menu-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.admin-menu-content ul li {
  cursor: pointer;
  width: 50%;
}

.admin-menu-content li img {
  height: 20px;
  width: 20px;
  margin-right: 16px;
}

.admin-menu-content .menu-admin-mobile {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
}

.admin-menu-content .menu-admin-mobile::-webkit-scrollbar {
  display: none;
}

.admin-menu-content .menu-admin-mobile li {
  display: inline-block;
  text-align: center;
  padding: 0 14px 0 14px;
  text-decoration: none;
}

.admin-menu-content .menu-admin-mobile .selected {
  border-bottom: 2px solid #53bfd4;
}
