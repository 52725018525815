.profile-info-container.mobile {
  padding: 24px !important;
}

.profile-info-container.desktop {
  padding: 80px 100px !important;
}

.profile-info-container .profile-info-content {
  /* border-radius: 6px; */
}

.profile-info-container .profile-info-content.mobile {
  font-size: 14px;
}

.profile-info-container .profile-info-content.desktop {
  font-size: 16px;
}

.profile-info-container .profile-info-content .address-title.mobile {
  height: 40px;
  padding-left: 24px;
  font-family: HelveticaNeuBold;
}

.profile-info-container .profile-info-content .address-title.desktop {
  height: 50px;
  padding-left: 40px;
  font-family: HelveticaNeuBold;
}

.profile-info-container .profile-info-content .address-info.mobile {
  padding: 24px;
  border: 1px solid #e3e2e4;
}

.profile-info-container .profile-info-content .address-info.desktop {
  padding: 40px;
  border: 1px solid #e3e2e4;
}
.profile-info-container .profile-info-content .box-pass.desktop {
  padding: 20px 40px 20px;
}
.profile-info-container .profile-info-content .box-pass.mobile {
  padding: 20px 24px 20px;
}
.profile-info-container .profile-info-content .box-pass button {
  text-decoration: underline;
  color: #53bfd4;
}

.profile-info-container .profile-info-content .box-pass.desktop button:hover {
  color: #27282e;
}