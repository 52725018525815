.admin-users-container.mobile {
  min-height: 100%;
}
.admin-users-container.desktop {
  padding: 65px 30px;
}

.admin-users-container .admin-users-content {
  position: relative;
}

.admin-users-container .admin-users-content .admin-users-text.mobile .title {
  font-size: 24px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}

.admin-users-container .admin-users-content .admin-users-text.desktop .title {
  font-size: 36px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}

.admin-users-container .admin-users-content .actions-container.mobile button {
  height: 24px;
  width: 24px;
  border: 0;
}

.admin-users-container .admin-users-content .actions-container.mobile button img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.admin-users-container .admin-users-content .actions-container.desktop button {
  height: 28px;
  width: 28px;
  border: 0;
}

.admin-users-container .admin-users-content .actions-container.desktop button img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.admin-users-container .admin-users-content .ant-pagination li,
.admin-users-container .admin-users-content .ant-pagination a {
  border: 0;
  background-color: transparent;
  color: black;
  font-weight: 100;
}

.admin-users-container .admin-users-content .ant-pagination-prev a,
.admin-users-container .admin-users-content .ant-pagination-next a {
  opacity: 1;
}

.admin-users-container .admin-users-content .ant-pagination-disabled a {
  opacity: 0.3;
}

.admin-users-container .admin-users-content .ant-pagination-item-active a {
  font-weight: bold;
  text-decoration: underline;
}
