.title-box .head-image-promo {
  width: 100%;
  object-fit: cover;
}

.title-box .head-image-promo.mobile {
  padding-top: 100px;
}

.title-box .head-image-promo.desktop {
  padding-top: 80px;
}

.title-box .section-title.desktop {
  padding: 5rem 80px 4rem;
}
.title-box .section-title.mobile {
  padding: 2rem 15px 2rem;
}
.title-box .section-title .title {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.title-box .section-title .title {
  font-family: HelveticaNeuBold;
}
.title-box .section-title .title.desktop {
  font-size: 32px;
}

.title-box .section-title .title.mobile {
  font-size: 18px;
}

.title-box .section-title .title-blue {
  color: #53bfd4;
}

.title-box .section-title .description {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.title-box .section-title .description.mobile {
  font-size: 14px;
}

.title-box .section-title .description.desktop {
  font-size: 16px;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .title-box .section-title .title.mobile {
    font-size: 20px;
  }
  .title-box .section-title .description.mobile {
    font-size: 15px;
  }
  .title-box .section-title.mobile {
    padding: 2rem 45px 2rem;
  }
}

@media only screen and (min-device-width: 1440px) {
  .title-box .section-title.desktop {
    padding: 5rem 115px 4rem;
  }
  .title-box .head-image-promo.desktop {
    padding-top: 80px;
  }
}

