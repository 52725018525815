.cart-detail-container {
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}

.cart-detail-container.desktop {
  padding-top: 80px;
}

.cart-detail-container.mobile {
  padding-top: 100px;
}

.cart-detail-container .cart-detail-parts.empty-cart {
  min-height: 100% !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .separator {
  height: 1px;
  width: 100%;
}

.cart-detail-container .cart-detail-parts .cart-detail-products.desktop {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 49px 40px 27px 120px !important;
}
.cart-detail-container .cart-detail-parts .cart-detail-products.desktop h4 {
  margin-bottom: 40px;
}

.cart-detail-container .cart-detail-parts .cart-detail-products.mobile {
  padding: 50px 20px !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles.mobile {
  padding: 0 8px 0 0 !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles.mobile .mobile-flex {
  display: flex;
  flex-direction: column;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles.desktop {
  color: #525358;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles.mobile {
  color: #525358;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles .product-price.desktop {
  width: 95px;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles .product-quantity.desktop {
  width: 75px;
  margin-right: 30px;
  text-align: center;
}

.cart-detail-container .cart-detail-parts .cart-detail-products .box-titles .product-quantity.mobile {
  margin-right: 20px;
  margin-left: 15px;
  text-align: center;
}

.cart-detail-container .box-empty.mobile {
  text-align: center;
}

.cart-detail-container .box-empty.desktop {
  text-align: center;
}

.cart-detail-container .box-empty.desktop img {
  height: 140px;
  width: 140px;
}
.cart-detail-container .box-empty.desktop h2 {
  color: #2b2d36;
  margin-top: 20px;
  margin-bottom: 13px;
}
.cart-detail-container .box-empty.desktop span {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}

.cart-detail-container .cart-detail-parts .cart-detail-info.desktop {
  overflow: hidden;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}
.cart-detail-container .cart-detail-parts .cart-detail-info.desktop .info-box {
  overflow: scroll;
  padding: 45px 80px 0 !important;
  height: calc(100% - 80px);
}
.cart-detail-container .cart-detail-parts .cart-detail-info.mobile .info-box {
  padding: 45px 60px !important;
}
.cart-detail-container .cart-detail-parts .cart-detail-info.desktop .info-box h5 {
  margin-bottom: 40px;
}

.cart-detail-container .cart-detail-parts .cart-detail-info.mobile {
  overflow: hidden;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .carrier.desktop {
  font-size: 16px;
  font-weight: 300 !important;
}
.cart-detail-container .cart-detail-parts .cart-detail-info textarea {
  width: 100%;
  min-height: 200px;
  background-color: white;
  border: 0;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 20px !important;
  resize: none;
  color: black;
  margin-top: 15px;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .carrier.desktop {
  font-size: 15px;
  font-weight: 300 !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .info-subtotal.desktop {
  font-size: 16px;
  font-weight: 300 !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .info-subtotal.mobile {
  font-size: 15px;
  font-weight: 300 !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .info-subtotal.data,
.cart-detail-container .cart-detail-parts .cart-detail-info .info-total.data {
  text-align: end;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .info-total.desktop {
  font-size: 16px;
  font-weight: bold !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .info-total.mobile {
  font-size: 15px;
  font-weight: bold !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .info-subtotal-text {
  color: #a9a9ab !important;
  font-size: 11px;
  margin-bottom: 8px;
  margin-top: 4px;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #53bfd4;
  border-radius: 50%;
  outline: none;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black input[type='radio']:before {
  content: '';
  display: block;
  width: 50%;
  height: 50%;
  margin: 25% auto;
  border-radius: 50%;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black input[type='radio']:checked {
  border: 2px solid #53bfd4 !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black input[type='radio']:checked:before {
  background: #53bfd4;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black.desktop span {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black.mobile span {
  font-size: 14px;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .next-btn.desktop {
  height: 80px;
  width: 100%;
  border: 0;
  font-size: 20px;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .next-btn.desktop:hover {
  background-color: #27282e !important;
}

.cart-detail-container .cart-detail-parts .cart-detail-info .next-btn.mobile {
  height: 84px;
  width: 100%;
  border: 0;
  font-size: 16px;
}

.cart-detail-container .btn-accept-order {
  background-color: #f5f4fb !important;
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  .cart-detail-container .cart-detail-parts .cart-detail-products.mobile {
    padding: 50px 15px !important;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info.mobile .info-box {
    padding: 45px 30px !important;
  }
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .cart-detail-container .cart-detail-parts .cart-detail-info.mobile .info-box {
    padding: 45px 45px !important;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-products.mobile {
    padding: 85px 45px 130px !important;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-products .box-titles.mobile .mobile-flex {
    display: flex;
    flex-direction: row;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-products .box-titles.mobile {
    padding: 0 8px 0 50px !important;
  }

  .cart-detail-container .cart-detail-parts .cart-detail-products .box-titles .product-price.mobile {
    padding-left: 35px;
  }
}
@media only screen and (min-device-width: 1440px) {
  .cart-detail-container.desktop {
    padding-top: 80px;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-products.desktop {
    padding: 50px 50px 27px 120px !important;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info.desktop .info-box {
    padding: 50px 20% !important;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info.desktop .info-box h5 {
    margin-bottom: 40px;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info .next-btn.desktop {
    height: 84px;
    width: 100%;
    border: 0;
    font-size: 21px;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info .info-subtotal.desktop {
    font-size: 16px;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info .info-total.desktop {
    font-size: 16px;
  }
  .cart-detail-container .cart-detail-parts .cart-detail-info .checkbox-black.desktop span {
    font-size: 14px;
  }
}
