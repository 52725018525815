.btn-register{
  width: 100% !important;
  height: auto !important;
}
.btn-register:hover {
  color: #53bfd4 !important;
}

.btn-register.mobile {
  font-size: 12px !important;
}

.btn-register.desktop {
  font-size: 12px !important;
}