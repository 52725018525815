.register-container.mobile {
  min-height: 100%;
}

.register-container.desktop {
  width: 500px;
}

.register-container .register-content {
  position: relative;
}

.register-container .register-content .login-logo.desktop {
  width: 100%;
  margin-bottom: 60px;
  margin-top: 0;
}
.register-container .register-content .login-logo.mobile {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.register-container .register-content .back-btn {
  position: absolute;
  cursor: pointer;
}

.register-container .register-content .back-btn.mobile {
  height: 24px;
  width: 24px;
  top: 5px;
  left: -10px;
}

.register-container .register-content .back-btn.desktop {
  height: 32px;
  width: 32px;
  top: 8px;
  left: -60px;
}

.register-container .register-content .register-text.mobile .title {
  font-size: 18px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}

.register-container .register-content .register-text.mobile .text {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}

.register-container .register-content .register-text.desktop .title {
  font-size: 22px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}

.register-container .register-content .register-text.desktop .text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}

.register-container .register-content .register-form.mobile .placeholder-form {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.register-container .register-content .register-form.desktop .placeholder-form {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.register-container .register-content .register-form.mobile,
.register-container .register-content .register-form.mobile input {
  color: black;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.register-container .register-content .register-form.desktop,
.register-container .register-content .register-form.desktop input {
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.register-container .register-content .register-form input {
  margin-top: 0px;
  margin-bottom: 4px;
  height: 40px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
  /* padding-left: 30px !important; */
  color: black;
}

.register-container .register-content .register-form input:hover,
.register-container .register-content .register-form input:focus {
  border: 1px solid black;
}

.register-container .register-content .register-form input.is-invalid {
  border: 1px solid red;
  background-image: url(/misc/icons/input_error.svg);
}

.register-container .register-content .register-button.mobile {
  font-size: 14px;
  padding: 0;
  margin: 36px auto;
}

.register-container .register-content .register-button.desktop {
  margin-top: 36px;
  font-size: 14px;
  padding: 0;
}

.register-container .register-content .register-button.desktop:hover {
  background-color: #53bfd4 !important;
}

.register-container .register-content .register-button.desktop:disabled,
.register-container .register-content .register-button.mobile:disabled {
  background-color: #27282e !important;
  opacity: 0.3;
}

/* CHECK */
.login-container .login-form .round-check .container {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 0px;
  padding-right: 0px;
}

.login-container .login-form .round-check.mobile .container {
  font-size: 12px;
}

.login-container .login-form .round-check.desktop .container {
  font-size: 14px;
}

.login-container .login-form .round-check .container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.login-container .login-form .round-check .container .checkmark {
  position: relative;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid black;
  margin-right: 4px;
}

.login-container .login-form .round-check.mobile .container .checkmark {
  width: 22px;
}

.login-container .login-form .round-check.desktop .terms-btn {
  text-decoration: underline;
  text-align: left;
  margin-bottom: 3px;
}
.login-container .login-form .round-check.mobile .terms-btn {
  text-decoration: underline;
  text-align: left;
  margin-left: 3px;
  font-size: 12px;
  width: 100%;
}

.login-container .login-form .round-check.mobile {
  /* width: 100%; */
}

.login-container .login-form .round-check.mobile .text-box {
  width: 100%;
  display: flex;
  align-items: center;
}

.login-container .login-form .container input:checked ~ .checkmark {
  background-color: green;
  border: 0;
}

.login-container .login-form .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.login-container .login-form .container input:checked ~ .checkmark:after {
  display: block;
}

.login-container .login-form .container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (min-width: 320px) and (max-height: 575px) {
  .register-container .register-content .back-btn.mobile {
    left: -24px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1095px) {
  #inputModal .text-box.mobile {
    padding: 3rem 6rem 1rem;
  }
}

.login-container .login-form.desktop .form-row {
  width: 400px !important;
}
