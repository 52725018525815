.top-filters-container {
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9ab !important;
  position: relative;
}

.top-filters-container .desktop .top-filters-title {
  font-size: 24px;
  font-weight: 400;
}

.top-filters-container .desktop .top-filters-title .bold-part {
  font-size: 28px;
  font-weight: 500;
  font-family: HelveticaNeuBold;
}

.top-filters-container .mobile .top-filters-title {
  font-size: 18px;
  font-weight: 400;
}

.top-filters-container .mobile .top-filters-title .bold-part {
  font-size: 24px;
  font-weight: 500;
  font-family: HelveticaNeuBold;
}

.top-filters-container .search-container.mobile .form-control {
  position: relative;
  height: 30px;
  padding-right: 30px;
  font-size: 12px;
}

.top-filters-container .search-container.mobile img {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 20%;
  opacity: 0.5;
  z-index: 1;
}

.top-filters-container .search-container.desktop .form-control {
  position: relative;
  height: 40px;
  padding-right: 40px;
}

.top-filters-container .search-container.desktop img {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 25%;
  opacity: 0.2;
  z-index: 1;
}
.top-filters-container .suggestions-container div {
  cursor: pointer;
}

.top-filters-container .search-word-info.mobile {
  font-size: 12px;
}

.top-filters-container .search-word-info .btn-close {
  background-color: white;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.top-filters-container .search-word-info .btn-close.mobile {
  height: auto;
}

.top-filters-container .search-word-info .btn-close img {
  width: 18px;
  height: 18px;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.top-filters-container .fadeInDown {
  animation-name: fadeInDown;
}
.top-filters-container .animated {
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

.top-filters-container .filters-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  z-index: 1;
  margin-top: 5px
}

.top-filters-container .filters-btn {
  background-color: #53bfd4;
  background-repeat: no-repeat;
  color: white;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  height: 30px;
  font-size: 14px;
  width: 100%;
}

.top-filters-container .layout-btn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.top-filters-container .icon-layout-grid {
  background-image: url(/misc/icons/grid_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-filters-container .icon-layout-list {
  background-image: url(/misc/icons/list_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-filters-container .icon-layout-grid.no-selected {
  opacity: 0.2;
}

.top-filters-container .icon-layout-list.no-selected {
  opacity: 0.2;
}

.top-filters-container .search-container input {
  border: 1px solid #a9a9ab;
  font-size: 14px;
  font-weight: 200;
  height: 40px;
  border-radius: 0 !important;
}

.top-filters-container .search-container input::placeholder {
  color: #a9a9ab !important;
}

.top-filters-container input:focus,
.top-filters-container button:focus {
  outline: 0 !important;
  box-shadow: none !important;
  color: #525358 !important;
}

.top-filters-container .input-group-btn span {
  position: absolute;
  height: 100%;
  right: 30px;
  color: black;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  
  .top-filters-container .search-container.mobile .form-control {
    position: relative;
    height: 40px;
    padding-right: 50px;
    font-size: 15px;
  }

  .top-filters-container .filters-btn {
    height: 40px;
  }

}
@media only screen and (min-device-width: 1700px) {
  
}
@media only screen and (min-device-width: 2200px) {
 
}
