.empty-box-products {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.empty-box-products.desktop img {
  width: 90px;
  height: 80px;
  margin-bottom: 10px;
}
.empty-box-products.desktop span {
  font-size: 16px;
  color: #6a6565;
}
.empty-box-products.mobile img {
  width: 90px;
  height: 80px;
  margin-bottom: 10px;
}
.empty-box-products.mobile span {
  font-size: 16px;
  color: #6a6565;
}
