.recommended-container a:hover {
  text-decoration: none;
}

.recommended-container .section-title.mobile span {
  font-size: 16px;
  padding-left: 4px;
  padding-right: 4px;
}

.recommended-container .section-title.desktop span {
  font-size: 24px;
}
.recommended-container .section-title-brand.mobile {
  text-align: center;
}
.recommended-container .section-title-brand span {
  font-weight: bold;
  padding-left: 4px;
  padding-right: 4px;
}
.recommended-container .section-title-brand.mobile span {
  font-size: 20px;
}

.recommended-container .section-title-brand.desktop span {
  font-size: 30px;
}
.recommended-container .section-title span strong {
  font-family: HelveticaNeuBold;
}

.recommended-product-box {
  position: relative;
}

.recommended-product-box .product-info {
  padding: 15px;
}

.recommended-product-box .product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.recommended-product-box .product-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: 60px;
  font-size: 13px;
}

.recommended-product-box .product-cat,
.recommended-product-box .product-price {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.recommended-product-box .product-name.mobile {
  font-size: 12px;
  height: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  font-weight: bolder;
}

.recommended-product-box .product-name.desktop {
  font-size: 14px;
  height: 40px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  font-weight: bolder;
}

.recommended-product-box .product-cat.mobile {
  font-size: 12px;
}

.recommended-product-box .product-cat.desktop {
  font-size: 14px;
}
.recommended-product-box .product-ref.mobile {
  font-size: 12px;
}

.recommended-product-box .product-ref.desktop {
  font-size: 12px;
}

.recommended-product-box .product-price.mobile {
  font-size: 10px;
  font-weight: bold;
}

.recommended-product-box .product-price.desktop {
  font-size: 14px;
  font-weight: bold;
}
.recommended-product-box .product-pvp.mobile {
  font-size: 10px;
}

.recommended-product-box .product-pvp.desktop {
  font-size: 14px;
}

.recommended-product-box .show-detail {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.recommended-product-box .show-detail img {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.recommended-container .recommended-image {
  position: relative;
  overflow: hidden;
}

.recommended-container .recommended-image.mobile {
  height: 150px;
}

.recommended-container .recommended-image.desktop {
  height: 300px;
}

.recommended-container .recommended-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.recommended-image .hover-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.recommended-image:hover .hover-img {
  display: inline;
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.recommended-container .slick-prev,
.recommended-container .slick-prev:before,
.recommended-container .slick-next,
.recommended-container .slick-next:before,
.recommended-container .slick-next:focus,
.recommended-container .slick-prev:focus,
.recommended-container .slick-next:hover,
.recommended-container .slick-prev:hover {
  opacity: 1 !important;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  opacity: 1;
  /* background: #27282e; */
  transition: all 0.3s ease-in-out;
  content: '' !important;
}

.recommended-container.desktop .slick-prev {
  left: -50px !important;
  z-index: 1;
}

.recommended-container.desktop .slick-next {
  right: -50px !important;
  z-index: 1;
}
.recommended-container.mobile .slick-prev {
  left: 0px !important;
  z-index: 1;
}

.recommended-container.mobile .slick-next {
  right: 0px !important;
  z-index: 1;
}

.recommended-container .slick-prev:before {
  background-image: url('/misc/icons/arrow_left_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.recommended-container .slick-next:before {
  background-image: url('/misc/icons/arrow_right_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.recommended-container.desktop {
  padding-left: 50px;
  padding-right: 50px;
}

.recommended-container.mobile {
  padding-left: 8px;
}
/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .recommended-product-box .product-pvp.mobile {
    font-size: 9px;
  }
  .recommended-product-box .product-price.mobile {
    font-size: 9px;
  }
}
/* <----- iphone 6/7/8 ----> */
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .recommended-product-box .product-pvp.mobile {
    font-size: 9px;
  }
  .recommended-product-box .product-price.mobile {
    font-size: 9px;
  }
}
