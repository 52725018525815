html,
body,
#root,
#root > div {
  height: 100%;
  background-color: #f3f2f9;
}

.login-container .login-image {
  width: 100%;
  height: auto;
}
.login-container .login-image.mobile .login-image-background {
  height: auto;
  width: 100%;
  object-fit: cover;
  background-color: #f3f2f9;
}
.login-container .login-image.desktop .login-image-background {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: #f3f2f9;
}

.login-container .login-form {
  color: black;
  background-color: #f3f2f9;
}

.login-container .login-form.mobile {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  height: auto;
}
.login-container .login-form .box-register {
  border-top: solid 1px #e3e2e4;
}

.login-container .login-form .box-register {
  white-space: pre-line;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #525358;
}

.login-container .login-form .box-register .title {
  color: black;
  font-weight: bold !important;
}

.login-container .login-form.desktop {
  padding-left: 10%;
  padding-right: 10%;
}

.login-container .login-form input {
  height: 40px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
}

.login-container .login-form.mobile input,
.login-container .login-form.mobile .usename-input p,
.login-container .login-form.mobile .password-input p {
  font-size: 12px;
}

.login-container .login-form.desktop input,
.login-container .login-form.desktop .usename-input p,
.login-container .login-form.desktop .password-input p {
  font-size: 14px;
}

.login-container .login-form input:hover,
.login-container .login-form input:focus {
  border: 1px solid black;
}

.login-container .login-form .title.desktop {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
}

.login-container .login-form .login-logo.desktop {
  width: 100%;
  margin-top: 30px;
}

.login-container .login-form .title.mobile {
  font-size: 24px;
  text-align: center;
  font-weight: bolder;
}

.login-container .login-form .login-logo.mobile {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.login-container .login-form .usename-input {
  position: relative;
}

.login-container .login-form .usename-input input,
.login-container .login-form .password-input input {
  /* padding-left: 40px; */
}

.login-container .login-form .usename-input p,
.login-container .login-form .password-input p {
  padding-left: 10px;
  margin: 0;
}

.login-container .login-form input.is-invalid {
  border: 1px solid red;
  background-image: url(/misc/icons/input_error.svg);
  background-size: 14px 14px;
}

.login-container .login-form.mobile .error {
  font-size: 12px;
}

.login-container .login-form.desktop .error {
  font-size: 14px;
}

.login-container .login-form input.is-valid {
  background-image: url(/misc/icons/input_ok.svg);
  background-size: 14px 14px;
}

.login-container .login-form .inputs-container span {
  font-size: 14px;
}

.login-container .login-form .recover {
  text-decoration: underline;
  cursor: pointer;
  color: #a9a9ab;
}

.login-container .login-form .recover:hover {
  color: black;
}

.login-container .login-form.mobile .recover {
  font-size: 12px;
}

.login-container .login-form.desktop .recover {
  font-size: 14px;
}

.login-container .login-form a,
.login-container .login-form a:hover,
.login-container .login-form a:focus {
  color: black;
  text-decoration: underline;
}

.login-container .login-form .mobile button {
  border-radius: 0 !important;
  height: 35px !important;
  font-size: 14px !important;
  width: 150px !important;
  padding: 0 !important;
}
.login-container .login-form .desktop button {
  border-radius: 0 !important;
  height: 45px !important;
  font-size: 14px !important;
  width: 200px !important;
  padding: 0 !important;
}

.login-container .login-form .btn-register {
  background-color: #53bfd4;
  color: white;
}

.login-container .login-form .btn-register:hover {
  background-color: #2b2d36;
  color: white !important
}

.login-container .login-form .btn-login {
  background-color: #2b2d36;
  color: white;
}

.login-container .login-form .btn-login:hover {
  background-color: #53bfd4;
}

.login-container .login-form .btn-login:disabled {
  background-color: #2b2d36;
  opacity: 0.5;
}

.login-container .login-form .container {
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.login-container .login-form .round-check .container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.login-container .login-form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

.login-container .login-form .checkmark {
  position: absolute;
  top: 5;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid black;
}

.login-container .login-form .container input:checked ~ .checkmark {
  background-color: green;
  border: 0;
}

.login-container .login-form .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.login-container .login-form .container input:checked ~ .checkmark:after {
  display: block;
}

.login-container .login-form .container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.login-hidden, .login-email-hidden {
  display: none;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .login-container .login-image.mobile {
    height: auto;
  }
  .login-container .login-form.mobile {
    padding: 50px 200px 50px;
  }
  .login-container .login-form .title.mobile {
    font-size: 32px;
  }

  /* .login-container .login-form .subtitle.mobile {
    font-size: 15px;
  } */
}
@media only screen and (min-width: 1400px) {
  .login-container .login-form .title.desktop {
    font-size: 32px;
  }

  /* .login-container .login-form .subtitle.desktop {
    font-size: 15px;
  } */
}

.login-container .login-form.desktop .form-row {
  width: 400px !important;
}
