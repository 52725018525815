.box-products-list.mobile {
  height: 110px;
}

.box-products-list.desktop {
  height: auto;
}

.box-products-list .img-cart-product {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  object-fit: contain;
}

.box-products-list .text-cart.mobile {
  font-size: 12px;
  font-weight: 300;
}

.box-products-list .text-cart.desktop {
  font-size: 14px;
  font-weight: 300;
}

.box-products-list .text-cart .price {
  font-weight: bold;
}

.box-products-list .btn-remove-cart {
  width: 34px;
  height: 34px;
  padding: 0;
  background: none !important;
  background-image: url('/misc/icons/trash_gray.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 18px 18px !important;
}

.box-products-list .btn-remove-cart:hover {
  background-image: url('/misc/icons/trash_dark.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 18px 18px !important;
}
