.admin-edit-container.mobile {
  min-height: 100%;
  padding: 20px 30px;
}
.admin-edit-container.desktop {
  padding: 62px 90px;
}
  
.admin-edit-container .admin-edit-content {
  position: relative;
}
  
.admin-edit-container .admin-edit-content .admin-edit-text.mobile .title {
  font-size: 24px;
  font-family: HelveticaNeuBold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #26282e;
  text-align: center;
}
  
.admin-edit-container .admin-edit-content .admin-edit-text.desktop .title {
  font-size: 35px;
  font-family: HelveticaNeuBold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #26282e;
  text-align: center;
}
  
.admin-edit-container .admin-edit-content .admin-edit-form.mobile,
.admin-edit-container .admin-edit-content .admin-edit-form.mobile input {
  color: black;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}
  
.admin-edit-container .admin-edit-content .admin-edit-form.desktop,
.admin-edit-container .admin-edit-content .admin-edit-form.desktop input {
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}
  
.admin-edit-container .admin-edit-content .admin-edit-form input {
  margin-top: 15px;
  height: 50px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
  padding-left: 30px !important;
  color: black;
}
  
.admin-edit-container .admin-edit-content .admin-edit-form input:hover,
.admin-edit-container .admin-edit-content .admin-edit-form input:focus {
  border: 1px solid black;
}
  
.admin-edit-container .admin-edit-content .admin-edit-form input.is-invalid {
  border: 1px solid red;
  background-image: url(/misc/icons/input_error.svg);
}
  
.admin-edit-container .admin-edit-content .admin-edit-button.mobile {
  padding: 0;
  margin: 36px auto;
  font-size: 16px;
}
  
.admin-edit-container .admin-edit-content .admin-edit-button.desktop {
  padding: 0;
  margin: 36px auto;
  font-size: 16px;
}
  
.admin-edit-container .admin-edit-content .admin-edit-button:disabled {
  opacity: 0.3;
}

.admin-edit-container .back-btn.btn-transparent {
  height: auto !important;
  width: auto !important;
}

.admin-edit-container .back-btn.desktop {
  position: absolute;
  top: 40px;
  left: 0px;
  margin: 42px 35px;
}
  
.admin-edit-container .back-btn.mobile {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 15px;
  z-index: 5;
}
  
.admin-edit-container .icon-back.mobile {
  height: 18px;
  width: 18px;
}
  
.admin-edit-container .icon-back.desktop {
  height: 20px;
  width: 20px;
}
  
.admin-edit-container .back-text {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}
  
.admin-edit-container .back-text.mobile {
  font-weight: 300;
  font-size: 14px;
}
  
.admin-edit-container .back-text.desktop {
  font-weight: 400;
  font-size: 16px;
}

@media only screen and (min-width: 700px) and (max-width: 1095px) {
  .admin-edit-container.mobile {
    padding: 40px 60px;
  }
}

@media only screen and (min-device-width: 1440px) {
  .admin-edit-container.desktop {
    padding: 65px 160px;
  }

  .admin-edit-container .back-btn.desktop {
    top: 40px;
  }
}
  