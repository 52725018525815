.orders-container.desktop {
  padding: 80px 6% !important;
}

.orders-container.mobile {
  padding: 20px !important;
}

.orders-container .separator {
  height: 1px;
  width: 100%;
}

.orders-container .orders-headers {
  color: slategray;
}

.orders-container .orders-headers.mobile {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
}

.orders-container .orders-headers.desktop {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
}

.orders-container.desktop .pvpr-info,
.orders-container.mobile .pvpr-info {
  color: #a9a9ab;
  font-size: 11px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.orders-container .empty-orders-container .empty-orders-parts .text-empty {
  white-space: pre-wrap;
}

.empty-orders-container .empty-orders-parts .box-empty.mobile img {
  height: 120px;
  width: 120px;
}

.empty-orders-container .empty-orders-parts .box-empty.desktop img {
  height: 150px;
  width: 150px;
}

.empty-orders-container .empty-orders-parts .box-empty.desktop span {
  margin-top: 30px;
  margin-bottom: 13px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  font-family: HelveticaNeuBold;
  color: #747474;
}

.empty-orders-container .empty-orders-parts .box-empty.mobile span {
  margin-top: 30px;
  margin-bottom: 13px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  font-family: HelveticaNeuBold;
  color: #747474;
}

@media only screen and (min-device-width: 1440px) {
  .empty-orders-container .empty-orders-parts.desktop {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .empty-orders-container {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .orders-container.desktop {
    padding: 80px 2% !important;
}
.order-item-container .item-content.desktop {
  font-size: 14px;
  height: 305px !important;
}
}
