.order-product-item {
  background-color: white;
  height: 70px;
  /* border-radius: 10px; */
  margin-bottom: 8px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.order-product-item .img-item img {
  width: 100%;
  max-width: 80px;
  height: 60px;
  overflow: hidden;
  object-fit: contain;
  border-radius: 10px 0px 0px 10px;
}

.order-product-item .product-name.desktop,
.order-product-item .product-brand.desktop,
.order-product-item .product-price.desktop,
.order-product-item .product-price-pvpr.desktop,
.order-product-item .product-count.desktop,
.order-product-item .product-quantity.desktop {
  width: 100px;
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.order-product-item .product-name.mobile,
.order-product-item .product-brand.mobile,
.order-product-item .product-price.mobile,
.order-product-item .product-count.mobile,
.order-product-item .product-quantity.mobile {
  color: black;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.order-product-item .product-name.mobile {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.order-product-item .product-brand.desktop {
  text-align: center;
}

.order-product-item .product-price.desktop {
  font-weight: bold;
  text-align: center;
}

.order-product-item .product-price-pvpr.desktop {
  font-weight: 300;
  color: #a9a9ab;
  text-align: center;
}

.order-product-item .product-price.mobile {
  font-weight: bold;
}

.order-product-item .product-price-pvpr.mobile {
  font-weight: 300;
  color: #a9a9ab;
}

.order-product-item .product-count.desktop {
  width: 80px;
  text-align: end;
  padding-right: 12px;
}

.order-product-item .product-count.mobile {
  width: 60px;
  text-align: end;
  padding-right: 6px;
}

.order-product-item .product-quantity.desktop {
  text-align: center;
}

.order-product-item .product-quantity.mobile {
  width: 20px;
  font-weight: bold;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .order-product-item .product-name.mobile,
  .order-product-item .product-brand.mobile,
  .order-product-item .product-count.mobile,
  .order-product-item .product-quantity.mobile {
    font-size: 13px;
  }
  .order-product-item .product-price.mobile {
    font-weight: bold;
    font-size: 13px;
  }
}
