.products-container {
  position: relative;
  margin: -6px 0 0 0 !important;
}
.scroll-top-menu {
  position: fixed !important;
  top: 0;
  z-index: 2;
  height: 100%;
  overflow: scroll;
}
.scroll-normal-menu {
  position: relative;
  overflow: scroll;
  height: 100%;
}

@keyframes fadeIn {
  from {
    transform: translate3d(0, -1%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 1%, 0);
  }
}

.products-container .fadeInDown {
  animation-name: fadeIn;
}

.scroll-top-products {
  margin-left: 25% !important
}
.scroll-normal-products {
  position: relative;
}

.head-image-shop{
  position: absolute;
  z-index: 3;
}
.filters-container-mobile {
  padding: 30px;
}

.head-image-shop {
  width: 100%;
  object-fit: cover;
}

.head-image-shop.mobile {
  padding-top: 106px;

}
.head-image-shop-filters.mobile {
  padding-top: 100px !important;

}

.head-image-shop.desktop {
  padding-top: 80px;

}


.products-container .filters-container-desktop {
  padding: 127px 3% 200px;
}
.box-loading.desktop {
  height: 100vh;
}
.box-loading.mobile {
  height: 400px;
}
.products-container .box-loading-products.desktop {
  height: 100%;
}
.products-container .box-loading-products.mobile {
  height: 800px;
}

.products-container .shop-container.desktop {
  padding: 50px 5% 250px;
  overflow: scroll;
}
.head-image-shop .home-slider.mobile img {
  height: 100%;
}

.products-container .shop-container.desktop .pvpr-info,
.products-container .shop-container.mobile .pvpr-info {
  color: #a9a9ab;
  font-size: 11px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.prices-container {
  width: 40%;
  text-align: end;
}

.product-price-header {
  width: 100%;
  font-weight: normal;
  color: black;
  font-size: 13px;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-right: 0px;
  align-items: center;
}

.product-price-header.pvpr {
  color: #a9a9ab;
}

.product-info-header {
  width: 60%;
}

@media only screen and (min-width: 748px) and (max-width: 850px) {
  .products-container .shop-container.mobile {
    padding: 30px 80px !important;
  }
  .box-loading.mobile {
    height: 700px;
  }
  .products-container .filters-container-desktop {
    padding: 127px 0% 80px;
  }
}
@media only screen and (min-width: 851px) and (max-width: 1240px) {
  .products-container .shop-container.mobile {
    padding: 30px 80px !important;
  }
  .box-loading.mobile {
    height: 700px;
  }
  .products-container .filters-container-desktop {
    padding: 127px 0.5% 80px;
  }
}

@media only screen and (max-device-width: 1400px) {
  .products-container .filters-container-desktop {
    padding: 127px 1% 80px;
  }
}

/* @media only screen and (min-device-width: 1440px) {
  .products-container .shop-container.desktop {
    padding: 50px 5%;
  }
} */
