.products-count.mobile {
  font-size: 12px;
}

.products-count.desktop {
  font-size: 14px;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .filters-box.desktop {
    padding: 0 115px;
  }
}