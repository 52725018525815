.terms-content {
  position: relative;
}
.terms-content .back-btn {
  position: absolute;
  cursor: pointer;
}
.terms-content .back-btn.mobile {
  height: 24px;
  width: 24px;
  top: 5px;
  left: -10px;
}
.terms-content .terms-text.mobile .title {
  font-size: 24px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
}
.terms-content .terms-text.desktop .title {
  font-size: 28px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
}

.terms-content .terms-text.desktop .ok-btn:hover {
  background-color: #27282e !important;
}

.terms-content .terms-text.mobile .terms-text-description .text {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #898383;
  text-align: center;
}

.terms-content .terms-text.desktop .terms-text-description {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.terms-content .terms-text.desktop .terms-text-description .text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #898383;
}

.terms-content .back-btn.desktop {
  height: 32px;
  width: 32px;
  top: 4px;
  left: -60px;
} 
