.media-container video {
  margin-bottom: -6px;
}

.media-container .media-content {
  position: relative;
}
.media-container .media-content .img-video-box {
  width: 100%;
}
.media-container .media-content .img-video-box.desktop img {
  /* max-height: auto; */
  width: 100%;
  object-fit: cover;
}
.media-container .media-content .img-video-box.mobile img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
.media-container .media-content .img-video-box.desktop .react-player {
  position: relative;
  top: 0;
  left: 0;
}

.media-container .media-content .btn-play {
  position: absolute;
}
.media-container .media-content.desktop .btn-play img {
  width: 105px;
  height: 105px;
}
.media-container .media-content.mobile .btn-play img {
  width: 80px;
  height: 80px;
}

.media-container .media-content.desktop .btn-close {
  position: absolute;
  top: 5%;
  right: 3%;
}
.media-container .media-content.mobile .btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.media-container .media-content.desktop .btn-close img {
  height: 40px;
  width: 40px;
}
.media-container .media-content.mobile .btn-close img {
  height: 40px;
  width: 40px;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .media-container .media-content .img-video-box.mobile img {
    /* height: 475px; */
  }
}

@media only screen and (min-device-width: 1440px) {
  .media-container .media-content .img-video-box.desktop img {
    /* height: 750px; */
  }
}
@media only screen and (min-device-width: 1920px) {
  /* .media-container {
    padding-left: calc(50% - 960px);
    padding-right: calc(50% - 960px);
  } */
   .media-container .media-content .img-video-box.desktop img {
    /* height: 950px; */
  }
}
