.contactus-container {
  background-color: #f5f4fb;
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}

.contactus-container.mobile {
  padding-top: 100px;
}

.contactus-container.desktop {
  padding-top: 80px;
}

.contactus-container .head-image {
  width: 100%;
  object-fit: cover;
}

.contactus-container .contactus-content.mobile {
  padding: 15px;
}

.contactus-container .contactus-content.desktop {
  padding: 100px;
  width: 80%;
  margin: 0 auto;
}

.contactus-container .contactus-content .contactus-text.mobile .title {
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  text-align: center;
  font-family: HelveticaNeuBold;
}

.contactus-container .contactus-content .contactus-text.mobile .text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.contactus-container .contactus-content .contactus-text.desktop .title {
  font-size: 21px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: black;
  font-family: HelveticaNeuBold;
}

.contactus-container .contactus-content .contactus-text.desktop .text {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-form.mobile,
.contactus-container .contactus-form.mobile input,
.contactus-container .contactus-form.mobile textarea {
  color: #525358;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-form.mobile .placeholder-form {
  margin-top: 15px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-form.desktop .placeholder-form {
  margin-top: 18px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-form.desktop,
.contactus-container .contactus-form.desktop input,
.contactus-container .contactus-form.desktop textarea {
  color: #525358;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-form input {
  height: 47px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
  padding-left: 30px !important;
  color: black;
}

.contactus-container .contactus-form input:hover,
.contactus-container .contactus-form input:focus {
  border: 1px solid black;
}

.contactus-container .contactus-form textarea {
  height: 250px;
  background-color: white;
  border: 0;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 20px !important;
  resize: none;
  color: black;
  border-radius: 0 !important;
}

.contactus-container .contactus-form textarea:hover,
.contactus-container .contactus-form textarea:focus {
  border: 1px solid black;
}

.contactus-container .contactus-button {
  text-align: center;
  text-decoration: none;
  border: none;
  color: white;
}

.contactus-container .contactus-button.valid:hover {
  background-color: #53bfd4 !important;
}

.contactus-container .contactus-button.mobile {
  margin: 36px auto;
}

.contactus-container .contactus-button.desktop {
  margin-top: 36px;
}

.contactus-container input.is-invalid,
.contactus-container textarea.is-invalid {
  border: 1px solid red;
  background-image: none;
}

.contactus-container .contactus-button:disabled {
  opacity: 0.3;
}

.contactus-container .contactus-content .contactus-form .contactus-dropdown.mobile {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-content .contactus-form .contactus-dropdown.desktop {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}

.contactus-container .contactus-content .contactus-form .dropdown-button {
  height: 47px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
  padding-left: 30px !important;
  color: black;
}
.contactus-container .contactus-content .contactus-form .dropdown-button {
  color: #747474;
}
.contactus-container .contactus-content .contactus-form li {
  color: #747474;
}
.contactus-container .contactus-content .contactus-form .placeholder {
  color: #747474 !important;
}

.contactus-container .contactus-content .contactus-form .dropdown-button:hover,
.contactus-container .contactus-content .contactus-form .dropdown-button:focus {
  border: 1px solid black;
}

.contactus-container .contactus-content .contactus-form .dropdown-icon {
  height: 30px;
  width: 30px;
}

.contactus-container .contactus-content .contactus-form .dropdown-options {
  z-index: 1;
  width: 100%;
  background-color: white;
  padding-left: 30px !important;
}

.contactus-container .contactus-content .contactus-form .dropdown-options.mobile {
  position: absolute;
}

.contactus-container .contactus-content .contactus-form .dropdown-options.desktop {
  position: relative;
}

.contactus-container .contactus-content .contactus-form .dropdown-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .contactus-container .contactus-content.mobile {
    padding: 15px 130px;
  }
}

@media only screen and (min-device-width: 1440px) {
  .contactus-container.desktop {
    padding-top: 80px;
  }
  .contactus-container .contactus-content.desktop {
    padding: 100px;
    width: 75%;
  }
}
@media only screen and (min-device-width: 1700px) {
  .contactus-container .contactus-content.desktop {
    padding: 100px;
    width: 70%;
  }
}
@media only screen and (min-device-width: 2200px) {
  .contactus-container .contactus-content.desktop {
    padding: 100px;
    width: 60%;
  }
}
