.activate-box {
  background-color: #f3f2f9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.activate-box .login-logo.mobile{
  width: 92px;
  height: 36px;
  object-fit: contain;
  margin-bottom: 2rem;
}
.activate-box .login-logo.desktop{
  width: 100px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 3rem;
}
.activate-box .text-box-activate.desktop h3 {
  font-family: HelveticaNeuBold;
  font-size: 28px;
}
.activate-box .text-box-activate.mobile h3 {
  font-family: HelveticaNeuBold;
  font-size: 18px;
}
.activate-box .text-box-activate.desktop {
  width: 30%;
  height: 400px;
}
.activate-box .text-box-activate.mobile {
  width: 80%;
  height: 400px;
}
.activate-box .text-box-activate.mobile .description {
  font-size: 14px;
}
.activate-box .text-box-activate.desktop .description {
  font-size: 16px;
  margin-bottom: 3rem;
}
.activate-box .text-box-activate span {
  color: #898383;
}

@media only screen and (min-width: 748px) and (max-width: 1120px) {
  .activate-box .text-box-activate.mobile {
    width: 50%;
  }
}
