/*---- modal ----*/
.modal-backdrop {
    z-index: 1 !important;
}
  
.simpleModalImage-box .modal {
    text-align: center;
}
  
.simpleModalImage-box .modal.fade .simple-modal-image-dialog.mobile {
    transform: translate3d(0, 0vh, 0) !important;
}
  
.simpleModalImage-box .modal.show .simple-modal-image-dialog.mobile {
    transform: translate3d(0, 0, 0) !important;
}
  
.simpleModalImage-box .simple-modal-image-dialog.mobile {
    max-width: 100% !important;
}
  
.simpleModalImage-box .simple-modal-image-dialog.desktop {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    max-width: 600px;
}
  
.simpleModalImage-box .simple-modal-image-content {
    justify-content: center !important;
    height: auto;
    width: 100% !important;
}
  
.simpleModalImage-box .simple-modal-image-content.mobile {
    border-top-right-radius: 21px !important;
    border-top-left-radius: 21px !important;
    height: 60vh;
    width: 100% !important;
}
  
.simpleModalImage-box .simple-modal-image-body {
    width: 100%;
    height: auto;
}
  
.simpleModalImage-box .simple-modal-image-body.desktop {
    border-radius: 6px;
    position: relative;
    padding: 80px 100px 80px 100px !important;
}
  
.simpleModalImage-box .simple-modal-image-body.mobile {
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
}
  
.simpleModalImage-box .simple-modal-image-body .ok-btn:hover {
    background-color: #27282e !important;
}
  
.simpleModalImage-box .modal {
    margin: 0;
    padding: 0;
    border: 0;
}
  
.modal-error .modal {
    z-index: 1051 !important;
}
  
.simpleModalImage-box .modal.mobile {
    top: 55% !important;
}
  
.simpleModalImage-box .modal.desktop {
    top: 25% !important;
    overflow: visible;
}
  
.simpleModalImage-box .fade {
    transition: opacity 0.3s linear !important;
}
  
#simpleModalImage .btn-close {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: white;
    border-radius: 50%;
    transform: rotate(45deg);
    cursor: pointer;
    height: 40px;
    width: 40px;
}
  
#simpleModalImage .btn-close img {
    width: 35px;
    height: 35px;
}
  
#modalsimple .text-box.mobile .img-middle {
    width: 60px;
    height: 60px;
}
  
#modalsimple .text-box.desktop .img-middle {
    width: 80px;
    height: 80px;
}
  
#simpleModalImage .text-box.mobile {
    padding: 3rem 3rem 1rem;
}
  
#simpleModalImage .text-box.desktop {
    margin-bottom: 50px;
}
  
#simpleModalImage .text-box.mobile span {
    /* font-size: 20px; */
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.48px;
    text-align: center;
}

#simpleModalImage .text-box.mobile .anonymous-logo {
    width: 70%;
    height: auto;
    object-fit: cover;
    margin-bottom: 12px;
}

#simpleModalImage .text-box.desktop .anonymous-logo {
    width: 80%;
    height: auto;
    object-fit: cover;
    margin-bottom: 32px;
}
  
#simpleModalImage .text-box.mobile .title {
    font-size: 18px;
    font-weight: bolder;
}
  
#simpleModalImage .text-box.desktop .title {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 24px !important;
}
  
#simpleModalImage .text-box.mobile .description {
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 1.33 !important;
}
  
#simpleModalImage .text-box.desktop .description {
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.5 !important;
}
  
/* <----IPHONE X ------> */
@media only screen and (device-width: 375px) and (device-height: 812px) {
    .simpleModalImage-box .modal.mobile {
        top: 50% !important;
    }
}

@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
    .simpleModalImage-box .modal.mobile {
      top: 40% !important;
    }
}

/* <----- iphone 6/7/8 ----> */
@media only screen and (device-width: 375px) and (device-height: 667px) {
    .simpleModalImage-box .modal.mobile {
        top: 40% !important;
    }
}
  