.box-product a:hover {
  text-decoration: none;
}

.box-product .grid-layout {
  margin-bottom: 30px;
}

.box-product img {
  object-fit: cover;
}

.box-product .box-content.grid-layout .show-detail {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.box-product .box-content.grid-layout .show-detail img {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.box-product .box-content.grid-layout {
  background-color: #fff;
  position: relative;
}

.box-product .box-content.grid-layout .separator,
.box-product .list-layout .separator {
  height: 1px;
  width: 100%;
}

.box-product .list-layout {
  background-color: white;
  height: 80px;
  margin-bottom: 8px !important;
}

.box-product .list-layout .count-container {
  margin-left: 0 !important;
}

.box-product .list-layout .img-list img {
  width: 100%;
  max-width: 80px;
  height: 80px;
  overflow: hidden;
  object-fit: contain;
  border-radius: 10px 0px 0px 10px;
}

.box-product .grid-layout .img-grid {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.box-product .grid-layout .img-grid-mobile {
  width: 100%;
  height: 180px;
  object-fit: contain;
}

.box-product .product-info,
.box-product .product-price {
  padding: 0 0px;
}
.box-product .product-info {
  padding-top: 15px;
}

.box-product .product-price {
  padding-bottom: 15px;
}
.box-product .product-price {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #53bfd4 !important;
}

.box-product .product-price.desktop .price.pvpr {
  font-weight: 300;
  color: #a9a9ab;
  font-size: 12px;
}

.box-product .product-price.mobile .price.pvpr {
  font-weight: 300;
  color: #a9a9ab;
  font-size: 12px;
}

.box-product .product-price .price-before:first-child {
  position: relative;
  height: 25px;
}
.box-product .product-price.list .price-before:first-child {
  position: relative;
  height: 25px;
  align-items: center;
  display: flex;
}

.box-product .product-price.desktop .price-before:first-child::after {
  content: '';
  border-bottom: 2px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 45%;
  width: 100%;
}
.box-product .product-price.list.desktop .price-before:first-child:after {
  content: '';
  border-bottom: 2px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 45%;
  width: 100%;
}
.box-product .product-price.mobile .price-before:first-child::after {
  content: '';
  border-bottom: 2px solid #53bfd4;
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
}

.box-product .product-price.mobile {
  font-size: 12px;
}

.box-product .product-price.desktop {
  font-size: 14px;
}
.box-product .promotion-box {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 14px;
  height: 27px;
}

.box-product .product-info.list {
  width: 60%;
}

.box-product .product-info .product-name {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #d6edf3;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.box-product .product-info.mobile .product-name {
  font-size: 13px;
  -webkit-line-clamp: 2;
  min-height: 36px;
}
.box-product .product-info.desktop .product-name {
  font-size: 14px;
  min-height: 40px;
  -webkit-line-clamp: 2;
}

.box-product .product-name.list {
  width: 100%;
  color: black;
  display: inline-flex;
  align-items: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  /* display: -webkit-box; */
}

.box-product .product-description.list {
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  /* display: block; */
  align-items: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.box-product .product-info .product-description.grid {
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: whitesmoke;
}
.box-product .product-info.mobile .product-description.grid {
  min-height: 32px;
}
.box-product .product-info.desktop .product-description.grid {
  min-height: 32px;
}
.box-product .product-cat {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: white;
}

.box-product .product-cat.list {
  width: 100px;
  color: black;
}

.box-product .product-brand.list {
  width: 100px;
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.box-product .product-price {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: start;
  color: white;
  margin-bottom: 5px;
}

.box-product .prices-container.list {
  width: 40%;
  text-align: end;
}

.box-product .prices-container.list .price {
  width: 100%;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: black;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

.box-product .prices-container.list .price.pvpr {
  font-weight: 300;
  color: #a9a9ab;
}

.box-product .prices-container.list .price.before {
  text-decoration: line-through;
  color: #53bfd4;
}

.box-product .product-count.list {
  color: black;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.box-product .cart-btn {
  height: 30px;
  width: 100px;
  background-color: #53bfd4;
  border: 0;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.box-product .cart-btn:hover {
  background-color: #252530;
}

.box-product .cart-btn:disabled {
  opacity: 0.3;
}

.box-product .cart-btn img {
  width: 18px;
  height: 18px;
}

.box-product input:focus,
.box-product button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
@media only screen and (min-width: 801px) and (max-width: 1100px) {
  .box-product .cart-btn {
    width: 44px;
    font-size: 12px;
    margin-right: 3px;
  }
}
/* <---iphone 5/SE ----> */
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .box-product .product-price.mobile {
    font-size: 11px;
  }
  .box-product .product-price.mobile .price.pvpr {
    font-size: 11px;
  }
  .box-product .product-info,
  .box-product .product-price {
    padding: 0 5px 5px;
  }
}

@media only screen and (min-device-width: 1440px) {
  .box-product .product-price {
    margin-bottom: 0;
  }
}
