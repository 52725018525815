.recovery-box {
  background-color: #f3f2f9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.recovery-box .login-logo.mobile{
  width: 60%;
  height: auto;
  object-fit: contain;
  margin-bottom: 2rem;
}
.recovery-box .login-logo.desktop{
  width: 60%;
  height: auto;
  object-fit: contain;
  margin-bottom: 3rem;
}
.recovery-box .text-box-recovery.desktop h3 {
  font-family: HelveticaNeuBold;
  font-size: 28px;
}
.recovery-box .text-box-recovery.mobile h3 {
  font-family: HelveticaNeuBold;
  font-size: 18px;
}
.recovery-box .text-box-recovery.desktop {
  width: 30%;
  height: 400px;
}
.recovery-box .text-box-recovery.mobile {
  width: 80%;
  height: 400px;
}

.recovery-box .text-box-recovery .send-btn:hover {
  background-color: #53bfd4 !important;
}

.recovery-box .text-box-recovery .send-btn:disabled {
  background-color: #27282e !important;
}

.recovery-box .text-box-recovery a {
  text-decoration: none;
  color: white;
}

.recovery-box .text-box-recovery .recover-ok-btn:hover {
  background-color: #53bfd4 !important;
}

.recovery-box .text-box-recovery.mobile .description {
  font-size: 14px;
}
.recovery-box .text-box-recovery.desktop .description {
  font-size: 16px;
  margin-bottom: 3rem;
}
.recovery-box .text-box-recovery span {
  color: #898383;
}
.recovery-box .text-box-recovery input {
  height: 40px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
}
.recovery-box .text-box-recovery input {
  height: 40px;
  background-color: white;
  border: 0;
  border-radius: 0 !important;
}
.recovery-box .text-box-recovery input.is-invalid {
  border: 1px solid red;
  background-image: none;
}
.recovery-box .text-box-recovery input:hover,
.recovery-box .text-box-recovery input:focus {
  border: 1px solid black;
}
.recovery-box .text-box-recovery.mobile .input-box .btn-eye {
  right: 20px;
  position: absolute;
  height: 40px;
}
.recovery-box .text-box-recovery.desktop .input-box .btn-eye {
  right: 35px;
  position: absolute;
  height: 40px;
  top: 5px;
}
.recovery-box .text-box-recovery.desktop .input-box {
  position: relative;
  padding: 0 30px;
  height: 50px;
}
.recovery-box .text-box-recovery.mobile .input-box .icon-login-eye {
  padding: 4px 4px;
  width: 30px;
  height: 30px;
}
.recovery-box .text-box-recovery.desktop .input-box .icon-login-eye {
  padding: 4px 4px;
  width: 30px;
  height: 30px;
}
.recovery-box .text-box-recovery.desktop .text-invalid {
  padding: 0px 30px;
  width: 100%;
  margin-top: 2px;
  font-size: 15px;
}
.recovery-box .text-box-recovery.mobile input {
  font-size: 14px;
}
.recovery-box .text-box-recovery.desktop input {
  font-size: 15px;
}
.recovery-box .text-box-recovery.mobile .text-invalid {
  padding: 0px 20px;
  width: 100%;
  margin: 5px 0;
  font-size: 14px;
}
.recovery-box .text-box-recovery button:disabled {
  opacity: 0.5;
}

@media only screen and (min-width: 748px) and (max-width: 1120px) {
  .recovery-box .text-box-recovery.mobile {
    width: 50%;
  }
}
