.carousel-main-container .section-title {
  position: relative;
}
.carousel-main-container .section-title.desktop {
  padding: 2rem 1.5rem;
}

.carousel-main-container .carousel-products-container .carousel-products-padding {
  padding-bottom: 15px;
}

.carousel-main-container .carousel-products-container .carousel-products-padding-top {
  padding-top: 35px;
}

.carousel-main-container .carousel-products-container .section-title .title {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  font-family: HelveticaNeuBold;
}

.carousel-main-container .carousel-products-container .section-title .title.desktop {
  font-size: 28px;
}

.carousel-main-container .carousel-products-container .section-title .title.mobile {
  font-size: 18px;
}

.carousel-main-container .carousel-products-container .section-title .title {
  color: #53bfd4;
}

.carousel-main-container .carousel-products-container .section-title .description {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #525358;
}

.carousel-main-container .carousel-products-container .section-title .description.mobile {
  font-size: 14px;
}

.carousel-main-container .carousel-products-container .section-title .description.desktop {
  font-size: 15px;
}

.carousel-main-container .carousel-products-container .promotions-product-link,
.carousel-main-container .carousel-products-container .promotions-product-link:focus {
  color: white;
  font-size: 14px;
  opacity: 0.8;
}

.carousel-main-container .carousel-products-container .promotions-product-link:hover {
  color: white;
  opacity: 1;
}

.carousel-main-container .carousel-products-container .promotions.desktop .slick-slider {
  height: 400px;
}

.carousel-main-container .carousel-products-container .promotions.mobile .slick-slider {
  height: 250px;
}

.carousel-main-container .carousel-products-container .promotions.desktop .slick-list {
  margin-left: 25px;
  margin-right: 45px;
}

.carousel-main-container .carousel-products-container .promotions .slick-prev {
  left: 0px !important;
  z-index: 1;
}

.carousel-main-container .carousel-products-container .promotions .slick-next {
  right: 0px !important;
  z-index: 1;
}

.carousel-main-container .carousel-products-container .promotions .slick-prev,
.carousel-main-container .carousel-products-container .promotions .slick-prev:before,
.carousel-main-container .carousel-products-container .promotions .slick-next,
.carousel-main-container .carousel-products-container .promotions .slick-next:before,
.carousel-main-container .carousel-products-container .promotions .slick-next:focus,
.carousel-main-container .carousel-products-container .promotions .slick-prev:focus,
.carousel-main-container .carousel-products-container .promotions .slick-next:hover,
.carousel-main-container .carousel-products-container .promotions .slick-prev:hover {
  color: black !important;
  opacity: 1 !important;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  opacity: 1;
  /* background: whitesmoke; */
  transition: all 0.3s ease-in-out;
  content: '' !important;
}

.carousel-main-container .carousel-products-container .promotions .slick-prev:before {
  background-image: url('/misc/icons/arrow_left_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.carousel-main-container .carousel-products-container .promotions .slick-next:before {
  background-image: url('/misc/icons/arrow_right_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product.mobile {
  height: 200px;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product.desktop {
  height: 350px;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product.desktop img {
  object-fit: cover;
  height: 350px;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product.mobile img {
  object-fit: cover;
  height: 200px;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product .hover-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.carousel-main-container .carousel-products-container .promotions .promotions-product:hover .hover-img {
  display: inline;
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.carousel-main-container .carousel-products-container .promotions a {
  text-decoration: none;
}

.carousel-main-container .carousel-products-container .promotions .product-name {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
}

.carousel-main-container .carousel-products-container .promotions .product-name.desktop {
  font-size: 15px;
}

.carousel-main-container .carousel-products-container .promotions .product-name.mobile {
  font-size: 14px;
}

.carousel-main-container .carousel-products-container .promotions .product-price {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #53bfd4;
}

.carousel-main-container .carousel-products-container .promotions .product-price.mobile {
  font-size: 18px;
}

.carousel-main-container .carousel-products-container .promotions .product-price.desktop {
  font-size: 18px;
}

.carousel-main-container .carousel-products-container .promotions .product-price.strikeout {
  text-decoration: line-through;
}

.carousel-main-container .carousel-products-container .promotions .product-promotion {
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: white;
}

.carousel-main-container .carousel-products-container .promotions .product-promotion.desktop {
  font-size: 18px;
}

.carousel-main-container .carousel-products-container .promotions .product-promotion.mobile {
  font-size: 16px;
}

.carousel-main-container .carousel-products-container .see-all {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  text-decoration: underline;
  color: #53bfd4;
}

.carousel-main-container .carousel-products-container .see-all a,
.carousel-main-container .carousel-products-container .see-all a:hover {
  color: #53bfd4;
}

.carousel-main-container .carousel-products-container .see-all.mobile {
  font-size: 14px;
}

.carousel-main-container .carousel-products-container .see-all.desktop {
  top: 45%;
  right: 25px;
  position: absolute;
  font-size: 16px;
}
.carousel-main-container .custom-paging {
  background-color: transparent;
  border: 1px #27282E solid;
}

.carousel-main-container .slick-dots {
  display: flex !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;
}

.carousel-main-container.mobile .slick-dots {
  bottom: 27px !important;
  /* left: 45% !important; */
  display: table !important;
  margin: 0 auto !important;
}

.carousel-main-container.mobile .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}

.carousel-main-container.mobile .slick-dots li div {
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
}

.carousel-main-container .slick-dots li.slick-active div {
  background-color: #27282E !important;
}

.carousel-main-container .slick-dots li div {
  background-color: transparent !important;
}
.carousel-main-container .custom-paging:hover {
  background-color: #27282E !important;
}


@media only screen and (min-width: 748px) and (max-width: 1095px) {
  .carousel-main-container .carousel-products-container .section-title .description.mobile {
    font-size: 16px;
  }
  .carousel-main-container .carousel-products-container .promotions .product-name.mobile {
    font-size: 16px;
  }
  .carousel-main-container .carousel-products-container .section-title .title.mobile {
    font-size: 20px;
  }
}
@media only screen and (min-device-width: 1440px) {
  .carousel-main-container .carousel-products-container .section-title .description.desktop {
    font-size: 16px;
  }
  .carousel-main-container .carousel-products-container .promotions .product-name.desktop {
    font-size: 16px;
  }
  .carousel-main-container .carousel-products-container .section-title .title.desktop {
    font-size: 30px;
  }
}
@media only screen and (min-device-width: 1920px) {
  .carousel-main-container .carousel-products-container .section-title .description.desktop {
    font-size: 16px;
  }
  .carousel-main-container .carousel-products-container .promotions .product-name.desktop {
    font-size: 16px;
  }
  .carousel-main-container .carousel-products-container .section-title .title.desktop {
    font-size: 30px;
  }
  /* .carousel-main-container .carousel-products-container {
    padding-left: calc(50% - 960px);
    padding-right: calc(50% - 960px);
  } */
  .carousel-main-container .carousel-products-container .promotions .slick-prev {
    left: 0px !important;
    z-index: 1;
  }

  .carousel-main-container .carousel-products-container .promotions .slick-next {
    right: 0px !important;
    z-index: 1;
  }
}
