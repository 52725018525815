.breaking-container {
  height: 40px;
  width: 100%;
  position: fixed;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.hiddenNews {
  display: none;
}
.breaking-container.mobile {
  height: 50px;
}
.breaking-container.mobile .news-box.mobile {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: normal;
}
.breaking-container .news-box.mobile {
  width: 80%;
}
.breaking-container .news-box.desktop {
  padding-right: 110px;
  text-align: center;
}
.breaking-container.desktop .box-buttons {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.breaking-container .box-buttons button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  padding: 0;
  margin-right: 15px;
  height: 100%;
  width: 20px;
}
.breaking-container.mobile button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 0;
  padding: 0;
  margin-right: 15px;
  height: 50%;
  width: 20px;
}
.breaking-container .box-buttons button img,
.breaking-container .box-buttons button span {
  width: 25px;
}
.breaking-container .box-buttons button .next {
  width: 20px;
}
.breaking-container.mobile .next-mobile {
  width: 20px;
  margin-right: 10px;
}
.breaking-container.mobile .close-breaking-mobile {
  width: 20px;
}
.breaking-container.mobile .next-mobile img {
  width: 20px;
}
.breaking-container.mobile .close-breaking-mobile img {
  width: 25px;
}
.breaking-container a {
  text-decoration: none;
  color: white;
}
@media only screen and (min-device-width: 270px) and (max-device-height: 655px) {
  .breaking-container.mobile .next-mobile {
    width: 20px;
    margin-right: 0px;
  }
}
