.brand-detail-container {
  min-height: 100%;
  padding-bottom: 80px;
  overflow: auto;
}
.brand-detail-container a,
.brand-detail-container a:hover{
 text-decoration: none
}

.brand-detail-container.mobile {
  padding-top: 100px;
}

.brand-detail-container.desktop {
  padding-top: 80px;
}
.brand-detail-container .separator {
  height: 1px;
}

.brand-detail-container .brand-detail-content.desktop .brand-detail-info {
  padding: 80px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.brand-detail-container .brand-detail-content.desktop .brand-detail-info .brand-detail-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 12px;
}

.brand-detail-container .brand-detail-content.mobile .brand-detail-info.mobile .brand-logo {
  max-width: 100%
}

.brand-detail-container .brand-detail-content.desktop .brand-detail-info a {
  margin-top: 50px;
}

.brand-detail-container .brand-detail-content.desktop .brand-detail-info .see-btn:hover {
  background-color: #27282e !important;
}

.brand-detail-container .brand-detail-content.mobile .brand-detail-info {
  padding: 30px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
}

.brand-detail-container .brand-detail-content.mobile .brand-detail-info .brand-detail-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 12px;
}

.brand-detail-container .brand-detail-content.mobile .brand-detail-info a {
  margin-top: 24px;
  justify-content: center !important;
}

.brand-detail-container .brand-detail-content .brand-slider.desktop {
  overflow: hidden;
}

.brand-detail-container .brand-detail-content .brand-slider.desktop img {
  width: 100%;
  object-fit: cover;
  margin-bottom: -7px;
}

.brand-detail-container .brand-detail-content .brand-slider.mobile {
  overflow: hidden;
}

.brand-detail-container .brand-detail-content .brand-slider.mobile img {
  width: 100%;
}

.brand-detail-container .brand-detail-content .brand-slider .slick-prev,
.brand-detail-container .brand-detail-content .brand-slider .slick-prev:before,
.brand-detail-container .brand-detail-content .brand-slider .slick-next,
.brand-detail-container .brand-detail-content .brand-slider .slick-next:before,
.brand-detail-container .brand-detail-content .brand-slider .slick-next:focus,
.brand-detail-container .brand-detail-content .brand-slider .slick-prev:focus,
.brand-detail-container .brand-detail-content .brand-slider .slick-next:hover,
.brand-detail-container .brand-detail-content .brand-slider .slick-prev:hover {
  color: black !important;
  opacity: 1 !important;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  content: '' !important;
}

.brand-detail-container .brand-detail-content .brand-slider .slick-prev:before {
  background-image: url('/misc/icons/arrow_left_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.brand-detail-container .brand-detail-content .brand-slider .slick-next:before {
  background-image: url('/misc/icons/arrow_right_thin_dark.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 20px 40px !important;
}

.brand-detail-container .brand-detail-content .brand-slider .slick-prev {
  left: 5% !important;
  z-index: 1;
}

.brand-detail-container .brand-detail-content .brand-slider .slick-next {
  right: 5% !important;
  z-index: 1;
}

.brand-detail-container .brand-detail-content .brand-logo.mobile {
  font-size: 12px;
  padding: 50px 20px !important;
}

.brand-detail-container .brand-detail-content .brand-logo.desktop {
  font-size: 14px;
  padding: 75px 50px !important;
}
.brand-detail-container .brand-detail-content .brand-logo hr {
  margin-bottom: 28px;
}

.brand-detail-container .brand-detail-content .brand-description.mobile {
  font-size: 12px;
  font-weight: 300;
  padding: 30px 20px !important;
}

.brand-detail-container .brand-detail-content .brand-description.desktop {
  font-size: 14px;
  font-weight: 300;
  padding: 0px 50px !important;
}

.brand-detail-container .products-slider.mobile {
  padding: 30px 10px;
}

.brand-detail-container .products-slider.desktop {
  padding: 30px 42px;
}

.brand-detail-container .brand-footer.desktop,
.brand-detail-container .brand-footer.desktop img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.brand-detail-container .brand-footer.mobile,
.brand-detail-container .brand-footer.mobile img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.brand-detail-container .custom-paging {
  background-color: transparent;
  border: 1px #27282E solid;
}

.brand-detail-container .slick-dots {
  display: flex !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;
}

.brand-detail-container.mobile .slick-dots {
  position: relative;
  bottom: -7px !important;
  /* left: 45% !important; */
  display: table !important;
  margin: 0 auto !important;
}

.brand-detail-container.desktop .slick-dots {
  position: relative;
  bottom: -7px !important;
  /* left: 45% !important; */
  display: table !important;
  margin: 0 auto !important;
}

.brand-detail-container.desktop .slick-dots li {
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
}

.brand-detail-container.desktop .slick-dots li div {
  width: 15px !important;
  height: 15px !important;
  padding: 0 !important;
}

.brand-detail-container.mobile .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}

.brand-detail-container.mobile .slick-dots li div {
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
}

.brand-detail-container .slick-dots li.slick-active div {
  background-color: #27282E !important;
}

.brand-detail-container .slick-dots li div {
  background-color: transparent !important;
}

.brand-detail-container .custom-paging:hover {
  background-color: #27282E !important;
}
@media only screen and (min-width: 648px) and (max-width: 1100px) {
  .brand-detail-container .brand-detail-content .brand-logo.mobile {
    font-size: 12px;
    padding: 50px 50px !important;
  }

  .brand-detail-container .brand-detail-content .brand-description.mobile {
    font-size: 14px;
    padding: 50px 40px !important;
    height: 300px;
  }

  .brand-detail-container .brand-footer.mobile,
  .brand-detail-container .brand-footer.mobile img {
    height: 154px;
    width: 100%;
    object-fit: unset;
  }
}
@media only screen and (min-device-width: 1440px) {
  .brand-detail-container .brand-detail-content .brand-slider.desktop {
    overflow: hidden;
  }

  .brand-detail-container .brand-detail-content .brand-slider.desktop img {
    width: 100%;
    object-fit: contain;
    margin-bottom: -7px;
  }

  .brand-detail-container.desktop {
    padding-top: 80px;
  }
  .brand-detail-container .brand-detail-content .brand-logo hr {
    margin-bottom: 2.3rem;
  }
}
@media only screen and (min-device-width: 1700px) {
  .brand-detail-container .brand-detail-content .brand-logo.desktop {
    font-size: 14px;
    padding: 85px 50px !important;
  }
  .brand-detail-container .brand-detail-content .brand-logo hr {
    margin-bottom: 35px;
  }
  .brand-detail-container .brand-detail-content .brand-slider.desktop {
    overflow: hidden;
  }
}
